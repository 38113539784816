<div
    *ngIf="trend.hasValues && !trend.loading && !isBaselinePoint() && !identicalValues()"
    class="summary-line-container"
>
    <div class="summary-graph-container">
        <div class="summary-values">
            <span class="min-value">{{ 'trends.summary.min.abbrev' | translate }}: {{ min }}</span>
            <span class="min-value">{{ 'trends.summary.max.abbrev' | translate }}: {{ max }}</span>
        </div>
        <div class="summary-line-graph">
            <div class="summary-line-dot min" [style.background-color]="trend.color"></div>
            <div
                class="summary-line-bar low-half"
                [style.background-color]="trend.color"
                [style.width]="getLowHalfSize()"
            ></div>
            <div class="summary-line-dot avg" [style.border-color]="trend.color">
                <span class="avg-value" [style.transform]="getAvgPosition()"
                    >{{ 'trends.summary.avg.abbrev' | translate }}: {{ avg }}</span
                >
            </div>
            <div
                class="summary-line-bar high-half"
                [style.background-color]="trend.color"
                [style.width]="getHighHalfSize()"
            ></div>
            <div class="summary-line-dot max" [style.background-color]="trend.color"></div>
        </div>
    </div>
    <span
        class="channel-tooltip-icon sum-icon"
        [matTooltipPosition]="'right'"
        matTooltipClass="multiline-tooltip"
        matTooltip="{{ 'trends.summary.sum.abbrev' | translate }}: {{ sum }}"
    >
        <img src="./assets/svg/info-icon.svg" alt="" />
    </span>
</div>
<div *ngIf="trend && trend.loading">
    <mat-spinner color="primary" diameter="23"></mat-spinner>
</div>

<div class="warn-no-data" *ngIf="trend && !trend.loading && !trend.hasValues && !trend.error">
    <span
        ><img class="warn-icon" src="./assets/svg/warning-icon.svg" alt="" />{{
            'trends.summary.no_data_available' | translate
        }}</span
    >
</div>

<div class="warn-no-data" *ngIf="trend && trend.error && !trend.loading">
    <span
    ><img class="warn-icon" src="./assets/svg/warning-icon.svg" alt="" />{{
            'trends.summary.data_timeout' | translate
        }}</span
    >
</div>
