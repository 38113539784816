import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeeplinksService } from './deeplinks.service';
import { BehaviorSubject } from 'rxjs';
import * as convertKeys from 'convert-keys';

import { Program } from '../models/program.model';
import { Context, Space } from 'ngx-global-nav';
import { AtlasPagesService, FilterEntity, Type } from 'atlas-pages-loader';
import { CookieService } from './cookie.service';

const CHILDREN_HIERARCHY_BY_TYPE = {
  Program: ['portfolio', 'registration'],
};

const SPACES_HIERARCHY = ['organization', 'spacegroup', 'site', 'meter', 'servicedeliverypoint'];
const SITE_HIERARCHY = ['site', 'spacegroup', 'meter', 'servicedeliverypoint'];
const CONTEXT_HIERARCHY = ['admingroup', 'organization'];

@Injectable()
export class NodeService {
  defaultNodeIds = [];
  loadingPrograms = false;
  loadingSpaces = false;
  loadingSites = false;
  hierarchyService: AtlasPagesService;
  readonly programs$ = new BehaviorSubject<Program[]>([]);
  readonly spaces$ = new BehaviorSubject<Space[]>(null);
  readonly sites$ = new BehaviorSubject<Space[]>(null);
  readonly contextList$ = new BehaviorSubject<Space[]>(null);
  contextIds: string[];

  filters: FilterEntity[] = [];
  userOnlyWithSites: boolean = false;

  cookieService: CookieService;

  constructor(
    private deeplinkService: DeeplinksService,
    private httpClient: HttpClient,
    cookieService: CookieService
  ) {
    this.deeplinkService.initialDeeplinks$.subscribe((deeplinks) => {
      if (deeplinks.trends && deeplinks.trends.length > 0) {
        this.defaultNodeIds = [...deeplinks.trends.map((trend) => trend[0])];
      }
    });
    this.cookieService = cookieService;
    this.hierarchyService = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
  }

  async getPrograms() {
    if (this.loadingPrograms) {
      return;
    }
    this.loadingPrograms = true;
    let allPrograms = await this.hierarchyService.getSpecificHierarchies(['program']);
    let allPortfolios = await this.hierarchyService.getSpecificHierarchies(['portfolio']);

    //filter duplicate portfolios by program id
    const programIds = allPrograms.map((program) => program.id);

    allPortfolios = allPortfolios.filter((portfolio) => {
      return !programIds.includes(portfolio.program_id);
    });

    //Get registration hierarchy for portfolios
    for (const portfolio of allPortfolios) {
      const filterBy = { id: portfolio.id, dr_type: 'PORTFOLIO' };
      const childrens = await this.hierarchyService.getSpecificHierarchies(['registration'], filterBy);
      portfolio.children = childrens;
    }

    //add all portfolio data to program object
    allPrograms = [...allPrograms, ...allPortfolios];
    this.programs$.next((convertKeys.toCamel({ programs: allPrograms }) as any).programs);
    this.loadingPrograms = false;
  }

  async getChildrenHierarchy(entityId: string, drType: string) {
    const filterBy = { id: entityId, dr_type: drType.toUpperCase() };
    const childrens = await this.hierarchyService.getSpecificHierarchies(CHILDREN_HIERARCHY_BY_TYPE[drType], filterBy);
    const convertedChilds = (convertKeys.toCamel({ programs: childrens }) as any).programs;
    return convertedChilds;
  }

  /**
   * Will look at all contexts, if any are an organization, will fetch organizations first.
   * Otherwise will fetch starting at the Sites level.
   *
   * @param contexts
   */
  async getSpaces(contexts?: Context[]) {
    if (contexts && contexts.length > 0) {
      this.filters = contexts.map((context) => {
        return { id: context.id, dr_type: context.space_type.toUpperCase() };
      });
      this.contextIds = contexts && contexts.length > 0 && contexts.map((context) => context.id);
    }
    if (this.loadingSpaces) {
      return;
    }
    if (this.userOnlyWithSites) {
      this.getSites();
      return;
    }
    this.loadingSpaces = true;
    const topLevel = [];
    for (const context of this.filters) {
      const allSpaces = await this.hierarchyService.getSpecificHierarchies(SPACES_HIERARCHY, context);
      const spaces = allSpaces.filter((entity) => context.id === entity.id);
      topLevel.push(...(convertKeys.toCamel({ spaces }) as any).spaces);
    }

    this.spaces$.next(topLevel);
    this.loadingSpaces = false;
  }
 
  async getSites() {
    if (this.loadingSites) {
      return;
    }
    this.loadingSites = true;
    let sites = await this.hierarchyService.getSpecificHierarchies(SITE_HIERARCHY);

    this.spaces$.next((convertKeys.toCamel({ sites }) as any).sites);
    this.loadingSites = false;
  }

  async getContextList() {
    const spaces = await this.hierarchyService.getSpecificHierarchies(CONTEXT_HIERARCHY);
    if (!spaces || (spaces && spaces.length === 0)) {
      this.userOnlyWithSites = true;
      this.getSites();
    } else {
      this.contextList$.next(spaces as Space[]);
    }
  }
}
