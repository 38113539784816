import { Component } from '@angular/core';
import {ChartErrorService} from "../../services/chart-error.service";

@Component({
    selector: 'app-chart-error',
    templateUrl: './chart-error.component.html',
    styleUrls: ['./chart-error.component.scss'],
})
export class ChartErrorComponent {
    isErrorAlertVisible: boolean;
    private message;

    constructor(private service: ChartErrorService) {
        this.isErrorAlertVisible = false;
        this.service.messages.subscribe(message => {
            this.displayAlert(message);
        });
    }

    private displayAlert(message): void {
        this.message = message;
        this.isErrorAlertVisible = true;
        setTimeout(() => {
            this.isErrorAlertVisible = false;
        }, this.message.timeout);
    }

    get isError(): boolean {
        return this.message.type === 'error';
    }

    get isSuccess(): boolean {
        return this.message.type === 'success';
    }

    get messageClassName() {
        return {
            'global-message': true,
            alert: true,
            'alert-danger': this.isError,
            'alert-success': this.isSuccess,
        };
    }

    get iconClassName() {
        return {
            icon: true,
            fa: true,
            'fa-ban': this.isError,
            'fa-check-circle': this.isSuccess,
        };
    }
}
