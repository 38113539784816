import { Injectable, Injector } from '@angular/core';

const COLORS = [
  '#4A33FE',
  '#32CC96',
  '#FBCB1E',
  '#662F98',
  '#B60C78',
  '#B12A31',
  '#333333',
  '#0085B9',
  '#EEAB1A',
  '#53585A',
  '#003E74',
];

@Injectable()
export class ColorService {
  static injector: Injector;
  readonly selectedColors: string[] = [];

  constructor() {}

  getColor(): string {
    const availableColor = COLORS.find(color => {
      return !this.selectedColors.includes(color);
    });
    this.selectedColors.push(availableColor);
    return availableColor;
  }

  recycleColor(color: string): void {
    const index = this.selectedColors.indexOf(color);
    this.selectedColors.splice(index, 1);
  }
}
