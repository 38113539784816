import { NodeDefinition } from 'enel-tree';

export interface NodeType {
  type: string;
  label: string;
  typeKey: string;
  pointType: string;
  disabledNodeTypes: NodeDefinition;
  iconDefinitions: any;
  resource: string;
}

export const ORG_ICON_DEFINITION: any = {
  AdminGroup: 'stars',
  Organization: 'language',
  Spacegroup: 'folder',
  Metergroup: 'folder',
  Site: 'apartment',
  Meter: 'offline_bolt',
  Servicedeliverypoint: 'sdp',
};

export const PROGRAM_ICON_DEFINITION: any = {
  Program: 'circle',
  Portfolio: 'topic',
  Product: 'topic',
  Registration: 'developer_board',
};

export const NodeTypes = [
  {
    type: 'organization',
    label: 'trends.node_type.organization',
    typeKey: 'spaceType',
    pointType: 'MeasuredPoint',
    iconDefinitions: ORG_ICON_DEFINITION,
    disabledNodeTypes: { spaceType: [] },
    resource: 'TRENDS',
  },
  {
    type: 'program',
    label: 'trends.node_type.program',
    typeKey: 'drType',
    pointType: 'TelemetryPoint',
    iconDefinitions: PROGRAM_ICON_DEFINITION,
    disabledNodeTypes: { drType: [] },
    resource: 'DR_ENTITY_MANAGEMENT',
  },
];
