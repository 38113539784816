import { Channel } from './channel.model';

export class ChannelCategory {
  id: string;
  displayLabel: string;
  channels: Channel[];

  constructor(id: string, displayLabel: string, channels: any[]) {
    this.id = id;
    this.displayLabel = displayLabel;
    this.channels = channels.map(
      (channel: any) =>
        new Channel(
          channel.id,
          channel.display_label,
          channel.point_type,
          channel.uom,
          channel.hierarchy_aggregation_type_id,
          channel.granularity,
          channel.base_granularity,
          channel.reporting_interval_ms,
          channel.description,
        ),
    );
  }
}
