<div class="date-container">
    <mat-form-field
        class="behind"
        [ngClass]="{ hovered: isHovered }"
        [focused]="isHovered"
        *ngIf="dependenciesLoaded"
        appearance="outline"
        floatLabel="always"
    >
        <mat-label [translate]="'trends.daterange.label'"></mat-label>
        <input type="text" [readonly]="true" [value]="" matInput />
    </mat-form-field>
    <date-picker
        class="front"
        #datepicker
        *ngIf="twoWayBinding"
        [options]="datepickerOptions"
        [(dateRange)]="twoWayBinding"
        (dateRangeChange)="selectDateRange($event)"
        (mouseenter)="isHovered = true"
        (mouseleave)="isHovered = false"
    ></date-picker>
</div>
