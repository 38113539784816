import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Daterange } from '../models/daterange.model';
import * as moment from 'moment';
import { Trend } from '../models/trend.model';

interface Deeplink {
  nodeType: string;
  granularity: string;
  startDate: string;
  endDate: string;
  compare: string;
  trends: any[];
  orgs: any[];
}

@Injectable()
export class DeeplinksService {
  initialDeeplinks$: BehaviorSubject<Deeplink> = new BehaviorSubject<Deeplink>(null);
  queryParams: URLSearchParams;

  constructor() {
    const deeplink = {
      nodeType: null,
      granularity: null,
      startDate: null,
      endDate: null,
      compare: null,
      trends: [],
      orgs: [],
    };
    this.queryParams = new URLSearchParams(document.location.search);

    if (this.queryParams.has('trends')) {
      let queryTrends = this.queryParams.get('trends');
      if (queryTrends) {
        const queryTrendsArray = queryTrends.split(',');
        for (let queryTrend of queryTrendsArray) {
          deeplink.trends.push(queryTrend.split('_'));
        }
      }
    }
    if (this.queryParams.has('orgs')) {
      let queryOrgs = this.queryParams.get('orgs');
      if (queryOrgs) {
        const queryOrgsArray = queryOrgs.split(',');
        for (let queryOrg of queryOrgsArray) {
          deeplink.orgs.push(queryOrg.split('_'));
        }
      }
    }

    if (this.queryParams.has('node_type')) {
      deeplink.nodeType = this.queryParams.get('node_type');
    }
    if (this.queryParams.has('granularity')) {
      deeplink.granularity = this.queryParams.get('granularity');
    }
    if (this.queryParams.has('start_date')) {
      deeplink.startDate = this.queryParams.get('start_date');
    }
    if (this.queryParams.has('end_date')) {
      deeplink.endDate = this.queryParams.get('end_date');
    }
    if (this.queryParams.has('compare')) {
      deeplink.compare = this.queryParams.get('compare');
    }

    this.initialDeeplinks$.next(deeplink);
  }

  updateNodeType(nodeType: string) {
    const queryParams = new URLSearchParams(document.location.search);
    queryParams.set('node_type', nodeType);
    this.setURLQueryParams(queryParams);
  }

  updateDateRange(dateRange: Daterange) {
    const queryParams = new URLSearchParams(document.location.search);
    queryParams.set('start_date', moment(dateRange.start).format('YYYY-MM-DDTHH:mm:ss'));
    queryParams.set('end_date', moment(dateRange.end).format('YYYY-MM-DDTHH:mm:ss'));
    this.setURLQueryParams(queryParams);
  }

  updateGranularity(granularity: string) {
    const queryParams = new URLSearchParams(document.location.search);
    queryParams.set('granularity', granularity);
    this.setURLQueryParams(queryParams);
  }

  updateTrends(trends: Trend[]) {
    const queryParams = new URLSearchParams(document.location.search);
    const queryTrends = trends.map(trend => {
      return `${trend.node.id}_${trend.channel.id}`;
    });
    queryParams.set('trends', queryTrends.toString());
    this.setURLQueryParams(queryParams);
  }

  updateCompare(compareType: string) {
    const queryParams = new URLSearchParams(document.location.search);
    queryParams.set('compare', compareType);
    this.setURLQueryParams(queryParams);
  }

  updateOrgs(orgs) {
    const queryParams = new URLSearchParams(document.location.search);
    const queryOrgs = orgs.map(org => {
      return `${org.id}`;
    });
    queryParams.set('orgs', queryOrgs.toString());
    this.setURLQueryParams(queryParams);
  }

  setURLQueryParams(queryParams: URLSearchParams) {
    const queryParamString = decodeURIComponent(queryParams.toString() ? `?${queryParams.toString()}` : '');
    if (history.pushState) {
      let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + queryParamString;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }
}
