import { Component, OnInit } from '@angular/core';
import { debounce } from 'rxjs/operators';
import { timer } from 'rxjs';
import { TrendsService } from '../services/trends.service';
import { LoadingService } from '../services/loading.service';
import { UserService } from '../services/user.service';
import { CookieService } from '../services/cookie.service';
import { MixPanelService } from '../services/mixpanel.service';

@Component({
  selector: 'app-historical-data-button',
  templateUrl: './historical-data.component.html',
  styleUrls: ['./historical-data.scss'],
})
export class HistoricalDataComponent implements OnInit {
  currentlyLoading = true;
  show = true;

  constructor(private cookieService: CookieService, private mixpanelService: MixPanelService) {
    this.cookieService = cookieService;
  }

  ngOnInit() {
    this.show = this.cookieService.getBrand() === 'ENELX';
  }

  goToProfiling(): void {
    this.mixpanelService.viewHistoricalData();
    // replace trends with space_ids to send to profiling app
    const { hostname, protocol, search } = document.location;
    let queryParamString = '';
    const queryParams = new URLSearchParams(search);
    if (queryParams.has('trends')) {
      let rawTrends = queryParams.getAll('trends');
      let trends = rawTrends.toString().split(',');
      let spaces = trends.reduce((spaces: string[], trend: string) => {
        let space = trend.replace(/_[^_]+$/, '');
        spaces.push(space);
        return spaces;
      }, []);
      queryParams.append('space_ids', spaces.join(','));
      queryParams.delete('trends');
    }
    queryParamString = decodeURIComponent(queryParams.toString() ? `?${queryParams.toString()}` : '');
    let profilingHostname = hostname
      .replace('trends.', 'profiling.')
      .replace('local.', 'profiling.')
      .replace(':9000', '');

    const profilingUrl = `${protocol}//${profilingHostname}/#/${queryParamString}`;
    window.open(profilingUrl);
  }
}
