import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from './cookie.service';

@Injectable()
export class ApiService {
  private headers: HttpHeaders;
  private apiLocation: string;
  private loginEndpoint: string;
  private forbiddenPath: string;

  constructor(private http: HttpClient, private cookieService: CookieService, @Inject(DOCUMENT) private document: any) {
    this.loginEndpoint = 'links/login';
    this.forbiddenPath = '/forbidden/index.html';

    if (this.cookieService.getMarketsMock()) {
      // TODO: build config for this
      this.apiLocation = 'http://wiremock:8888/v1/';
    } else {
      this.apiLocation = '/api/v1/';
    }

    this.headers = new HttpHeaders();
    console.log(this.cookieService.getEnocSession());
    if (this.cookieService.getEnocSession()) {
      this.headers = this.headers.set('Authorization', this.cookieService.getEnocSession());
    } else {
      this.redirectToLogin();
    }
  }

  /**
   * Common handler for get requests to API
   * @param url
   * @param params (optional)
   * @returns {Promise<any>}
   */
  async get(url: string, params?: object) {
    return await this.request('get', url, null, params);
  }

  /**
   * Common handler for post requests to API
   * @param url
   * @param body
   * @param params (optional)
   * @returns {Promise<any>}
   */
  async post(url: string, body: object, params?: string) {
    return await this.request('post', url, body, params);
  }

  /**
   * Common handler for delete requests to API
   * @param url
   * @param params (optional)
   * @returns {Promise<any>}
   */
  async delete(url: string, params?: string): Promise<any> {
    return await this.request('delete', url, null, params);
  }

  /**
   * Common handler for put requests to API
   * @param url
   * @param body
   * @param params (optional)
   * @returns {Promise<any>}
   */
  async put(url: string, body: object, params?: string): Promise<any> {
    return await this.request('put', url, body, params);
  }

  async redirectToLogin() {
    const url = this.document.location.pathname;
    const pathBrand = url.split('/')[1];
    let prezEndpoint = this.loginEndpoint;

    const { supportedBrands } = <any>window;

    if (supportedBrands && supportedBrands.includes(pathBrand)) {
      prezEndpoint = `${this.loginEndpoint}/?brand=${pathBrand}`;
    }

    console.log('redirectToLogin - redirecting to endpoint: ', prezEndpoint);
    if (prezEndpoint) {
      try {
        const links = await this.get(prezEndpoint);
        console.log('found links for user: ', links);
        const loginPage = links.LOGIN_PAGE ? links.LOGIN_PAGE.reference : links.reference;

        this.document.location = `${loginPage}&goto=${this.document.location}`;
        return;
      } catch (err) {
        console.error('redirect threw error', err);
        throw err;
      }
    } else {
      console.log('tried to redirect with a null endpoint');
    }
  }

  async redirectToForbidden() {
    const redirectLocation = document.location.origin + this.forbiddenPath + '?type=forbidden';
    console.log('Redirecting to ', redirectLocation);
    this.document.location = redirectLocation;
  }

  private async request(method, url, body, params) {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.set(key, params[key]);
      });
    }

    const opts = {
      params: httpParams,
      headers: this.headers,
    };

    let response: Observable<any>;
    if (method === 'put' || method === 'post' || method === 'patch') {
      response = this.http[method](this.apiLocation + url, body, opts);
    } else {
      response = this.http[method](this.apiLocation + url, opts);
    }

    const isSuccess = (code) => code >= 200 && code < 300;

    try {
      const res = await response.toPromise();
      if (res && res.data && isSuccess(res.code)) {
        return res.data;
      } else {
        console.log('Error: API Service - unexpected response format');
        throw res;
      }
    } catch (err) {
      if (err.status === 401 || (err.status == 403 && url.includes('/user/menu'))) {
        return this.redirectToLogin();
      } else if (err.status === 403) {
        return this.redirectToForbidden();
      } else if (err.status === 409) {
        return { ...err.error, status: err.status };
      } else {
        console.log('Error: API Service - ' + JSON.stringify(err.error));
        throw err;
      }
    }
  }
}
