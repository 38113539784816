<div class="chart-tabs" data-automation="chart-tabs">
    <div
        class="chart-tab"
        data-automation="chart-tab"
        [ngClass]="tab == selectedComparisonType ? 'active' : ''"
        *ngFor="let tab of tabs"
        (click)="changeTab(tab)"
        [translate]="tab.label"
    ></div>
</div>
