<div class="trend-card" *ngIf="!deleted">
    <mat-expansion-panel [expanded]="index === 0" togglePosition="before">
        <mat-expansion-panel-header
            class="panel-override"
            collapsedHeight="54px"
            expandedHeight="54px"
            data-automation="nodeHeader"
        >
            <mat-panel-title>
                <h6 class="card-title" data-automation="displayLabel">
                    {{ node.displayLabel }}
                </h6>
            </mat-panel-title>
            <mat-panel-description class="delete-card">
                <div class="custom-icon" (click)="unselect($event)">
                    <img src="./assets/svg/close-icon.svg" alt="" />
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div *ngIf="!loading">
            <div class="channel-section" *ngIf="getMeteredChannels().length > 0">
                <div class="channel-section-header">
                    <mat-label class="section-title">{{ 'trends.card.metered' | translate }}</mat-label>
                    <mat-form-field class="channels-multi-selector" appearance="outline" floatLabel="always">
                        <mat-label>{{ 'trends.card.metered_values' | translate }}</mat-label>
                        <mat-select
                            id="meteredChannels"
                            name="meteredChannels"
                            class="input-entry create-select"
                            [(ngModel)]="selectedMeteredChannels"
                            data-automation="meteredChannels"
                            placeholder="{{ 'trends.card.metered_channels_placeholder' | translate }}"
                            multiple
                        >
                            <mat-option
                                *ngFor="let channel of getMeteredChannels()"
                                [value]="channel"
                                [disabled]="disableChannel(channel)"
                                (click)="!disableChannel(channel) && handleSelectionChange($event, channel, 'Metered')"
                            >
                                {{ channel.displayLabel }}
                                <span
                                    class="channel-tooltip-icon"
                                    [matTooltipPosition]="'right'"
                                    matTooltipClass="multiline-tooltip"
                                    matTooltip="{{ 'trends.channel.base_granularity' | translate }}: {{
                                        channel.base_granularity || channel.granularity
                                    }}
                                {{ 'trends.channel.id' | translate }}: {{ channel.id }}
                                {{ 'trends.channel.description' | translate }}: {{ channel.description }}"
                                >
                                    <img src="./assets/svg/info-icon.svg" alt="" />
                                </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="separator"></div>
                <div class="selected-channels-section">
                    <ng-container *ngIf="selectedMeteredChannels.length > 0">
                        <div class="selected-channel" *ngFor="let trend of meteredTrends | async">
                            <mat-label class="channel-title">
                                {{ trend.channel.displayLabel }}
                                <span
                                    class="channel-tooltip-icon"
                                    [matTooltipPosition]="'right'"
                                    matTooltipClass="multiline-tooltip"
                                    matTooltip="{{ 'trends.channel.base_granularity' | translate }}: {{
                                        trend.channel.base_granularity || trend.channel.granularity
                                    }}
                                    {{ 'trends.channel.id' | translate }}: {{ trend.channel.id }}
                                    {{ 'trends.channel.description' | translate }}: {{ trend.channel.description }}"
                                >
                                    <img src="./assets/svg/info-icon.svg" alt="" />
                                </span>
                            </mat-label>
                            <br />
                            <mat-label class="granularity">{{ 'trends.granularity' | translate }}:</mat-label>
                            <mat-label class="granularity-value">
                                {{
                                    trend.channel.aggregationType === 'NONE'
                                        ? getChannelGranularityLabel(trend.channel.granularity)
                                        : getSelectedGranularityLabel()
                                }}
                            </mat-label>
                            <app-summary-line
                                [trend]="trend"
                                [channelId]="trend.channel.id"
                                [nodeId]="node.id"
                                [pointType]="'metered'"
                            ></app-summary-line>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="channel-section" *ngIf="getBaselineChannels().length > 0">
                <div class="channel-section-header">
                    <mat-label class="section-title">{{ 'trends.card.baseline' | translate }}</mat-label>
                    <mat-form-field class="channels-multi-selector" appearance="outline" floatLabel="always">
                        <mat-label>{{ 'trends.card.baseline_values' | translate }}</mat-label>
                        <mat-select
                            id="baselineChannels"
                            name="baselineChannels"
                            class="input-entry create-select"
                            [(ngModel)]="selectedBaselineChannels"
                            data-automation="baselineChannels"
                            placeholder="{{ 'trends.card.baseline_channels_placeholder' | translate }}"
                            multiple
                        >
                            <mat-option
                                *ngFor="let channel of getBaselineChannels()"
                                [value]="channel"
                                [disabled]="disableChannel(channel)"
                                (click)="!disableChannel(channel) && handleSelectionChange($event, channel, 'Baseline')"
                            >
                                <span *ngIf="channel.registration != undefined" class="reg-channel"
                                    >{{ channel.registration.display_label }}
                                    <br />
                                </span>
                                {{ channel.displayLabel || channel.display_label }}
                                <span
                                    class="channel-tooltip-icon"
                                    [matTooltipPosition]="'right'"
                                    matTooltipClass="multiline-tooltip"
                                    matTooltip="{{ 'trends.channel.base_granularity' | translate }}: {{
                                        channel.base_granularity || channel.granularity
                                    }}
                                {{ 'trends.channel.id' | translate }}: {{ channel.id }}
                                {{ 'trends.channel.description' | translate }}: {{ channel.description }}"
                                >
                                    <img src="./assets/svg/info-icon.svg" alt="" />
                                </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="separator"></div>
                <div class="selected-channels-section">
                    <ng-container *ngIf="selectedBaselineChannels.length > 0">
                        <div class="selected-channel" *ngFor="let trend of baselineTrends | async">
                            <mat-label class="channel-title">
                                {{ trend.channel.displayLabel }}
                                <span
                                    class="channel-tooltip-icon"
                                    [matTooltipPosition]="'right'"
                                    matTooltipClass="multiline-tooltip"
                                    matTooltip="{{ 'trends.channel.base_granularity' | translate }}: {{
                                        trend.channel.base_granularity || trend.channel.granularity
                                    }}
                                {{ 'trends.channel.id' | translate }}: {{ trend.channel.id }}
                                {{ 'trends.channel.description' | translate }}: {{ trend.channel.description }}"
                                >
                                    <img src="./assets/svg/info-icon.svg" alt="" />
                                </span>
                            </mat-label>
                            <ng-container *ngIf="trend.channel.registration != undefined">
                                <mat-label class="reg-channel">{{
                                    trend.channel.registration.display_label
                                }}</mat-label>
                            </ng-container>
                            <br />
                            <mat-label class="granularity">{{ 'trends.granularity' | translate }}:</mat-label>
                            <mat-label class="granularity-value">
                                {{
                                    trend.channel.aggregationType == 'NONE'
                                        ? getChannelGranularityLabel(trend.channel.granularity)
                                        : getSelectedGranularityLabel()
                                }}
                            </mat-label>
                            <app-summary-line
                                [trend]="trend"
                                [channelId]="trend.channel.id"
                                [nodeId]="node.id"
                                [pointType]="'baseline'"
                            ></app-summary-line>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="channel-section" *ngIf="getTelemetryChannels().length > 0">
                <div class="channel-section-header">
                    <mat-label class="section-title">{{ 'trends.card.telemetry' | translate }}</mat-label>
                    <mat-form-field class="channels-multi-selector" appearance="outline" floatLabel="always">
                        <mat-label>{{ 'trends.card.telemetry_values' | translate }}</mat-label>
                        <mat-select
                            id="telemetryChannels"
                            name="telemetryChannels"
                            class="input-entry create-select"
                            [(ngModel)]="selectedTelemetryChannels"
                            data-automation="telemetryChannels"
                            placeholder="{{ 'trends.card.telemetry_channels_placeholder' | translate }}"
                            multiple
                        >
                            <mat-option
                                *ngFor="let channel of getTelemetryChannels()"
                                [value]="channel"
                                [disabled]="disableChannel(channel)"
                                (click)="
                                    !disableChannel(channel) && handleSelectionChange($event, channel, 'Telemetry')
                                "
                            >
                                {{ channel.displayLabel }}
                                <span
                                    class="channel-tooltip-icon"
                                    [matTooltipPosition]="'right'"
                                    matTooltipClass="multiline-tooltip"
                                    matTooltip="{{ 'trends.channel.base_granularity' | translate }}: {{
                                        channel.base_granularity || channel.granularity
                                    }}
                                {{ 'trends.channel.id' | translate }}: {{ channel.id }}
                                {{ 'trends.channel.description' | translate }}: {{ channel.description }}"
                                >
                                    <img src="./assets/svg/info-icon.svg" alt="" />
                                </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="separator"></div>
                <div class="selected-channels-section">
                    <ng-container *ngIf="selectedTelemetryChannels.length > 0">
                        <div class="selected-channel" *ngFor="let trend of telemetryTrends | async">
                            <mat-label class="channel-title">
                                {{ trend.channel.displayLabel }}
                                <span
                                    class="channel-tooltip-icon"
                                    [matTooltipPosition]="'right'"
                                    matTooltipClass="multiline-tooltip"
                                    matTooltip="{{ 'trends.channel.base_granularity' | translate }}: {{
                                        trend.channel.base_granularity || trend.channel.granularity
                                    }}
                                    {{ 'trends.channel.id' | translate }}: {{ trend.channel.id }}
                                    {{ 'trends.channel.description' | translate }}: {{ trend.channel.description }}"
                                >
                                    <img src="./assets/svg/info-icon.svg" alt="" />
                                </span>
                            </mat-label>
                            <br />
                            <mat-label class="granularity">{{ 'trends.granularity' | translate }}:</mat-label>
                            <mat-label class="granularity-value">
                                {{
                                    trend.channel.aggregationType == 'NONE'
                                        ? getChannelGranularityLabel(trend.channel.granularity)
                                        : getSelectedGranularityLabel()
                                }}
                            </mat-label>
                            <app-summary-line
                                [trend]="trend"
                                [channelId]="trend.channel.id"
                                [nodeId]="node.id"
                                [pointType]="'telemetry'"
                            ></app-summary-line>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="loading">
            <mat-spinner color="primary" diameter="23"></mat-spinner>
        </div>
    </mat-expansion-panel>
</div>
