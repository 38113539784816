<div *ngIf="nodes.length > 0">
    <h6 class="trends-card-heading">
        {{ 'trends.cards.heading' | translate }}
    </h6>
    <span *ngIf="selectedNodeType.type === 'program'">
        {{ 'trends.cards.program.subheading' | translate }}
    </span>
    <span *ngIf="selectedNodeType.type === 'organization'">
        {{ 'trends.cards.orgs.subheading' | translate }}
    </span>
    <div class="line-spacer"></div>
    <div *ngFor="let node of nodes; let i = index" data-automation="channel-selector">
        <app-node-card [index]="i" [node]="node" (unselectNode)="unselectNode(node)"></app-node-card>
    </div>
</div>
