import { Component, Input, OnInit } from '@angular/core';
import { NodeSelectionService } from '../services/node-selection.service';
import { Node } from '../models/node.model';
import { NodeType, NodeTypes } from '../models/nodeType.model';
import { Program } from '../models/program.model';
import { NodeTypeService } from '../services/node-type.service';
import { UserService } from '../services/user.service';
import { DeeplinksService } from '../services/deeplinks.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() sidenav;
  selectedNodes: Node[] = [];
  numSelectedNodes = '';
  loadingNodes = true;

  private allNodeTypes = NodeTypes;
  nodeTypes: NodeType[] = [];
  selectedNodeType: NodeType;
  programs: Program[];

  constructor(
    private nodeSelectionService: NodeSelectionService,
    private nodeTypeService: NodeTypeService,
    private userService: UserService,
    private deeplinkService: DeeplinksService,
  ) {
    this.nodeSelectionService.selectedNodes$.subscribe((nodes) => {
      this.selectedNodes = nodes;
      this.numSelectedNodes = nodes.length > 0 ? `${nodes.length}` : '';
    });

    this.nodeTypeService.selectedNodeType$.subscribe((nodeType) => {
      if (nodeType) {
        this.selectedNodeType = nodeType;
        this.deeplinkService.updateNodeType(nodeType.type);
      }
    });

    this.nodeTypeService.loadingNodes$.subscribe((loading) => {
      this.loadingNodes = loading;
    });

    this.nodeTypeService.availableNodeTypes$.subscribe((nodeTypes) => {
      this.nodeTypes = nodeTypes;
    });

    this.userService.permissions$.subscribe((permissions) => {
      this.deeplinkService.initialDeeplinks$.subscribe((deeplinks) => {
        this.nodeTypeService.availableNodeTypes$.next(
          this.allNodeTypes.filter((nodeType) => {
            return this.userService.hasPermission(nodeType.resource, 'READ');
          }),
        );
        const isUtilityPortalResource = this.userService.hasPermission('UTILITY_PORTAL_DISPATCH', 'READ');
        this.nodeTypeService.setNodeType(deeplinks.nodeType, isUtilityPortalResource);
      });
    });
  }

  async ngOnInit() {}

  selectNodeType(nodeType: string) {
    this.nodeTypeService.setNodeType(nodeType);
  }
}
