import { Granularity } from './../models/granularity.model';
import { Component, OnInit } from '@angular/core';
import { GranularityService } from '../services/granularity.service';
import { ComparisonService } from '../services/comparison.service';
import { TrendsService } from '../services/trends.service';
import { ComparisonType } from './../models/comparisonType.model';
import { MixPanelService } from '../services/mixpanel.service';

@Component({
  selector: 'app-granularity-selector',
  templateUrl: './granularity-selector.component.html',
  styleUrls: ['./granularity-selector.component.scss'],
})
export class GranularitySelectorComponent implements OnInit {
  isInit = true;
  granularities: Granularity[] = [];
  selectedGranularity: Granularity;
  minReportingInterval: number;
  disableSelector: boolean;
  comparisonType: ComparisonType;
  filteredGranularitiesArr: Granularity[];

  constructor(
    private comparisonService: ComparisonService,
    private granularityService: GranularityService,
    private trendsService: TrendsService,
    private mixpanelService: MixPanelService,
  ) {
    this.comparisonService.selectedComparisonType$.subscribe(comparisonType => {
      this.comparisonType = comparisonType;
      if (comparisonType.type !== 'raw') {
        const filteredGran = this.filteredGranularities();
        if (!filteredGran.find(gran => gran.id == this.selectedGranularity.id)) {
          const defaultGranularity = this.granularityService.getDefaultGranularity(filteredGran);
          this.selectGranularity(defaultGranularity);
        }
      }
    });
  }

  async ngOnInit() {
    this.granularityService.availableGranularities$.subscribe(granularities => {
      if (granularities) {
        this.granularities = granularities;
      }
    });

    this.granularityService.selectedGranularity$.subscribe(granularity => {
      let previousGranularity = this.filteredGranularities().find(gran => gran === granularity);

      if (previousGranularity !== undefined) {
        this.selectedGranularity = previousGranularity;
      } else {
        this.selectedGranularity = this.filteredGranularities()[0];
      }
    });

    this.granularityService.minReportingInterval$.subscribe(interval => {
      this.minReportingInterval = interval;
      this.selectGranularity(this.selectedGranularity);
    });

    this.trendsService.trends$.subscribe(trends => {
      let noneAggregatedTrends = trends.filter(trend => trend.channel.aggregationType === 'NONE');
      if (trends.length) {
        if (noneAggregatedTrends.length === trends.length) {
          this.disableSelector = true;
        } else {
          this.disableSelector = false;
        }
      }
    });
  }

  selectGranularity(granularity: Granularity) {
    if (granularity) {
      this.mixpanelService.selectInterval(this.comparisonType.type, {
        display_label: granularity.display_label,
        duration_in_milliseconds: granularity.duration_in_milliseconds,
        duration_in_minutes: granularity.duration_in_minutes
      })
    }
    this.granularityService.validateAndSelectGranularity(granularity);
  }

  filteredGranularities() {
    if (this.comparisonType.type === 'raw') {
      return this.granularities;
    }
    this.filteredGranularitiesArr = this.granularities.filter(gran => !gran.export_tool_only);
    return this.filteredGranularitiesArr;
  }
}
