import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, map, switchMap, tap } from 'rxjs';
import { Daterange, DATE_FORMAT_STRING } from '../models/daterange.model';
import * as moment from 'moment-timezone';
import { DateRangeService } from './date-range.service';
import { GranularityService } from './granularity.service';
import { Granularity } from '../models/granularity.model';

const MAX_AUTO_REFRESH_GRANULARITY_MS = 900000;
const MIN_AUTO_REFRESH_GRANULARITY_MS = 30000;

const DEFAULT_AUTO_REFRESH_STATE = true;

@Injectable()
export class AutoRefreshService {
  dateRange: Daterange;
  interval: number;

  readonly autoRefreshEnabled$ = new BehaviorSubject<boolean>(DEFAULT_AUTO_REFRESH_STATE);
  readonly allowRefresh$ = new BehaviorSubject<boolean>(false);
  readonly intervalTime$ = new BehaviorSubject<number>(MAX_AUTO_REFRESH_GRANULARITY_MS);

  readonly isRefreshing$ = combineLatest([this.allowRefresh$, this.autoRefreshEnabled$]).pipe(
    map(([allowRefresh, autoRefreshEnabled]) => allowRefresh && autoRefreshEnabled),
  );

  readonly forceRefresh$ = new BehaviorSubject<boolean>(false);

  constructor(private dateRangeService: DateRangeService, private granularityService: GranularityService) {
    combineLatest([granularityService.selectedGranularity$, dateRangeService.selectedDateRange$]).subscribe(
      ([granularity, dateRange]) => {
        if (dateRange && this.checkIfDateRangeIsUpToNow(dateRange)) {
          if (granularity && this.isRefreshableGranularity(granularity)) {
            this.dateRange = dateRange;
            this.setIntervalByGranularity(granularity);
            if (!this.allowRefresh$.getValue()) {
              this.allowRefresh$.next(true);
            }
            return;
          }
        }
        this.allowRefresh$.next(false);
      },
    );

    const autoRefresh = combineLatest([this.allowRefresh$, this.autoRefreshEnabled$]).pipe(
      tap(([allowRefresh, autoRefreshEnabled]) => {
        if (allowRefresh && autoRefreshEnabled) {
          const time = this.interval / 1000;
          if (time > 60) {
            console.log(`Auto refresh enabled every ${time / 60} minutes`);
          } else {
            console.log(`Auto refresh enabled every ${time} seconds`);
          }
        } else {
          console.log('Auto refresh disabled');
        }
      }),
      switchMap(([allowRefresh, autoRefreshEnabled]) =>
        allowRefresh && autoRefreshEnabled ? this.intervalTime$.pipe(switchMap((time) => interval(time))) : [],
      ),
    );

    autoRefresh.subscribe((value) => {
      if (!this.checkIfDateRangeIsUpToNow(this.dateRange)) {
        this.dateRange.end = moment().add(5, 'seconds').format(DATE_FORMAT_STRING);
      }
      this.dateRangeService.setDateRange(this.dateRange);
      value = this.forceRefresh$.getValue();
      this.forceRefresh$.next(!value);
      console.log('REFRESH: ', value);
    });
  }

  isRefreshableGranularity(granularity: Granularity) {
    return Number(granularity.duration_in_milliseconds) <= MAX_AUTO_REFRESH_GRANULARITY_MS;
  }

  checkIfDateRangeIsUpToNow(dateRange: Daterange) {
    const now = moment().subtract(1, 'minutes');
    const endDate = moment(dateRange.end, DATE_FORMAT_STRING);
    return endDate.isSameOrAfter(now);
  }

  setIntervalByGranularity(granularity: Granularity) {
    const granularityMs = Number(granularity.duration_in_milliseconds);
    if (granularityMs < MIN_AUTO_REFRESH_GRANULARITY_MS) {
      this.interval = MIN_AUTO_REFRESH_GRANULARITY_MS;
      this.intervalTime$.next(MIN_AUTO_REFRESH_GRANULARITY_MS);
      return;
    }
    this.interval = granularityMs;
    this.intervalTime$.next(granularityMs);
  }

  setAutoRefresh(value) {
    this.autoRefreshEnabled$.next(value);
  }
}
