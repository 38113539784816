import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard, Permission } from './services/auth-guard.service';

export enum Routing {
  doc = 'doc',
  root = '',
  consumersenergy = 'consumersenergy',
  demo = 'demo',
}

const routes: Routes = [
  {
    path: Routing.root,
    component: AppComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { requiredPermissions: [Permission.read] },
  },
  { path: 'doc', redirectTo: '/doc/index.html' },
  {
    path: Routing.consumersenergy,
    component: AppComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [Permission.read] },
  },
  {
    path: Routing.demo,
    component: AppComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [Permission.read] },
  },
  {
    path: '**',
    redirectTo: '/' + Routing.root,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes )],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
