<div class="node-type-selector" *ngIf="nodeTypes.length > 0">
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label [translate]="'trends.node_type'"></mat-label>
        <mat-select
            [value]="selectedNodeType"
            [disabled]="loadingNodes"
            (selectionChange)="selectNodeType($event.value.type)"
        >
            <mat-option *ngFor="let nodeType of nodeTypes" [value]="nodeType">
                <span [translate]="nodeType.label"></span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<ng-template mat-tab-label>
    <span [translate]="selectedNodeType.label"></span>
    <div *ngIf="numSelectedNodes" class="trends-counter">{{ numSelectedNodes }}</div>
</ng-template>
<app-node-selector></app-node-selector>
