import { TrendsService } from './../services/trends.service';
import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { ExportService } from '../services/export.service';
import { MixPanelService } from '../services/mixpanel.service';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss'],
})
export class ExportButtonComponent implements OnInit {
  hasData = false;
  loading = true;

  constructor(
    private exportService: ExportService,
    private loadingService: LoadingService,
    private trendsService: TrendsService,
    private mixpanelService: MixPanelService
  ) {
    this.loadingService.hasTrendData$.subscribe(hasData => {
      this.hasData = hasData;
    });

    this.loadingService.loading$.subscribe(loading => {
      this.loading = loading;
    });
  }

  async ngOnInit() {}

  export(): void {
    this.mixpanelService.export();
    this.exportService.exportTrends(this.trendsService.trends$.value);
  }
}
