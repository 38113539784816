import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatepickerOptions, DateRange } from 'ngx-datepicker';
import { DateRangeService } from '../services/date-range.service';
import {
  DatePipe,
  FormatWidth,
  FormStyle,
  getLocaleDateFormat,
  getLocaleDayNames,
  getLocaleMonthNames,
  TranslationWidth,
} from '@angular/common';
import moment from 'moment-timezone';
import { I18nService } from '../services/i18n.service';
import { MixPanelService } from '../services/mixpanel.service';
import { ComparisonService } from '../services/comparison.service';

@Component({
  selector: 'app-daterange-selector',
  templateUrl: './daterange-selector.component.html',
  styleUrls: ['./daterange-selector.component.scss'],
})
export class DaterangeSelectorComponent implements OnInit {
  public dependenciesLoaded = false;
  private isInit = true;
  private datePipe: DatePipe;

  date = new FormControl(new Date());
  selectedDateRange: any;
  twoWayBinding: DateRange;
  datepickerOptions: DatepickerOptions;
  selectedComparisonType;
  isHovered = false;

  constructor(
    private translateService: TranslateService,
    private dateRangeService: DateRangeService,
    private i18nService: I18nService,
    private mixpanelService: MixPanelService,
    private comparisonService: ComparisonService,
  ) {
    this.comparisonService.selectedComparisonType$.subscribe((comparisonType) => {
      this.selectedComparisonType = comparisonType;
    });
    this.selectedDateRange = {
      start: moment(this.dateRangeService?.defaultDateRange.start),
      end: moment(this.dateRangeService?.defaultDateRange.end),
    };
    this.twoWayBinding = this.selectedDateRange;
    this.datepickerOptions = {
      alwaysShowCalendars: true,
      opens: 'right',
      autoApply: true,
      singleDatePicker: false,
      showDropdowns: true,
      timePicker: true,
      timePicker24Hour: true,
      timePickerSeconds: true,
      linkedCalendars: false,
      startDate: this.selectedDateRange.start,
      endDate: this.selectedDateRange.end,
      translation: 'en-US',
      locale: {},
    };
    // Default ranges while waiting for translations
    this.datepickerOptions.ranges = {
      ['Today']: [moment().startOf('day'), moment().endOf('day')],
      ['Last 7 Days']: [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
      ['Last 30 Days']: [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
      ['Last 90 Days']: [moment().subtract(89, 'days').startOf('day'), moment().endOf('day')],
    };
    this.translateService.get('trends.daterange.today.label').subscribe((todayLabel: string) => {
      this.datepickerOptions.ranges = {
        [todayLabel]: [moment().startOf('day'), moment().endOf('day')],
        [this.translateService.instant('trends.daterange.7days.label')]: [
          moment().subtract(6, 'days').startOf('day'),
          moment().endOf('day'),
        ],
        [this.translateService.instant('trends.daterange.30days.label')]: [
          moment().subtract(29, 'days').startOf('day'),
          moment().endOf('day'),
        ],
        [this.translateService.instant('trends.daterange.90days.label')]: [
          moment().subtract(89, 'days').startOf('day'),
          moment().endOf('day'),
        ],
        // [this.translateService.instant('trends.daterange.YTD.label')]: [moment().startOf('year'), moment().startOf('day')],
        // [this.translateService.instant('trends.daterange.year.label')]: [
        //   moment()
        //     .subtract(1, 'year')
        //     .add(1, 'day')
        //     .startOf('day'),
        //   moment().startOf('day'),
        // ],
      };
      this.datePipe = new DatePipe(this.i18nService.locale);
      this.datepickerOptions.locale = {
        daysOfWeek: this.getDayNames(),
        monthNames: this.getMonthNames(),
        applyLabel: this.translateService.instant('trends.daterange.apply'),
        cancelLabel: this.translateService.instant('trends.daterange.cancel'),
        customRangeLabel: this.translateService.instant('trends.daterange.custom.label'),
      };
      this.datepickerOptions.translation = this.i18nService.locale;
      this.dependenciesLoaded = true;
    });
  }

  async ngOnInit() {
    this.dateRangeService.selectedDateRange$.subscribe((daterange) => {
      this.selectedDateRange = daterange;
    });
  }

  selectDateRange(dateRange) {
    if (!this.isInit) {
      this.mixpanelService.selectDateRange(this.selectedComparisonType.type, {
        start: dateRange.start.toLocaleString(),
        end: dateRange.end.toLocaleString(),
      });
      this.dateRangeService.setDateRange(dateRange);
    } else {
      this.isInit = false;
    }
  }

  getDateFormat() {
    return getLocaleDateFormat(this.i18nService.locale, FormatWidth.Short);
  }

  getDayNames(): string[] {
    // @ts-ignore
    return getLocaleDayNames(this.i18nService.locale, FormStyle.Format, TranslationWidth.Short);
  }

  getMonthNames(): string[] {
    // @ts-ignore
    return getLocaleMonthNames(this.i18nService.locale, FormStyle.Format, TranslationWidth.Wide);
  }
}
