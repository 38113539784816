import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable()
export class TrendsApiService {
  constructor(private apiService: ApiService) {}

  async getPrograms() {
    try {
      return await this.apiService.get(`programs`);
    } catch (err) {
      console.log('Could not load programs.', err);
    }
  }

  async getNavHierarchy() {
    try {
      return await this.apiService.get(`nav/hierarchy`);
    } catch (err) {
      console.log(`Could not retrieve Nav Hierarchy:`, err);
    }
  }

  async getSites() {
    try {
      return await this.apiService.get(`spaces/hierarchy/sites`);
    } catch (err) {
      console.log(`Could not retrieve Nav Hierarchy:`, err);
    }
  }

  async getSpacesForType(spaceType: string, page: number) {
    try {
      return await this.apiService.get(`spaces/${spaceType}?page=${page}`);
    } catch (err) {
      console.log(`Could not retrieve Spaces for ${spaceType} Type:`, err);
    }
  }

  async getChannelsForSpace(spaceId) {
    try {
      return await this.apiService.get(`spaces/${spaceId}/channels/categories`);
    } catch (err) {
      console.log(`Could not retrieve Channels for space:`, err);
    }
  }
  async getChannelsForProgram(programId) {
    try {
      return await this.apiService.get(`programs/${programId}/channels/categories`);
    } catch (err) {
      console.log(`Could not retrieve Channels for Program`, err);
    }
  }
  async getChannelsForPortfolio(portfolioId) {
    try {
      return await this.apiService.get(`portfolios/${portfolioId}/channels/categories`);
    } catch (err) {
      console.log(`Could not retrieve Channels for Portfolio`, err);
    }
  }
  async getChannelsForRegistration(registrationId) {
    try {
      return await this.apiService.get(`registrations/${registrationId}/channels/categories`);
    } catch (err) {
      console.log(`Could not retrieve Channels for Registration:`, err);
    }
  }

  async getRegistrationsBaselineChannelsUnderEntity(entityType, entityId) {
    try {
      return await this.apiService.get(`entities/${entityId}/baseline-channels?type=${entityType.toUpperCase()}`);
    } catch (err) {
      console.log(`Could not retrieve Channels for Registration:`, err);
    }
  }

  async getTimezones() {
    try {
      return await this.apiService.get(`timezones`);
    } catch (err) {
      console.log('Could not load timezones.', err);
    }
  }

  async getGranularities(start_dttm: string, end_dttm: string, point_type: string, all?: boolean) {
    let params = {};
    params = { start_dttm, end_dttm, point_type };
    if (all) {
      params = { ...params, all };
    }
    try {
      return await this.apiService.get(`granularities`, params);
    } catch (err) {
      console.log('Could not load timezones.', err);
    }
  }
}
