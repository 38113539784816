import { Injectable } from '@angular/core';
import { AnalyticsService } from 'ngx-shared-services';
import { GlobalNavService, User } from 'ngx-global-nav';
import { BehaviorSubject } from 'rxjs';
import { ComparisonType } from '../models/comparisonType.model';

/**
 * A service to easily implement mixpanel tracking
 */
@Injectable()
export class MixPanelService {
    EVENTS = {
        'VIEW_TRENDS' : 'View Trends',
        'SELECT_NODE_TYPE' : 'Select Node Type',
        'EXPAND_SIDE_DRAWER' : 'Expand side drawer',
        'COLLAPSE_SIDE_DRAWER' : 'Collapse side drawer',
        'SEARCH' : 'Search',
        'EXPORT' : 'Select Export',
        'VIEW_HISTORICAL_DATA' : 'Select View Historical Data',
        'VIEW_COMPARE_TRENDS' : 'View Compare Trends',
        'SELECT_DATA_RANGE': 'Date range preset selected',
        'SELECT_INTERVAL': 'Select interval',
        'CHANNEL_SELECTED': 'Channel selected',
        'COMPARE_TO_PAST': 'View Compare To Past',
        'COMPARE_TO': 'Select Compare To',
        'VIEW_RAW_DATA': 'View Raw Data',
        // add new events here
    };
    APPLICATION_NAME = 'Trends'

    isInit = false
    userInformation$ = new BehaviorSubject<User>(null);

    constructor(private analyticsService: AnalyticsService, private globalNavService: GlobalNavService) {
        this.globalNavService.userData$.subscribe((user) => {
            if (user && !this.isInit) {
                this.userInformation$.next(user);
                this.analyticsService.init(user.user_id, this.APPLICATION_NAME);
                this.analyticsService.setPage(this.APPLICATION_NAME);
                this.isInit = true;
            }
        })
    }

    viewPage () {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.VIEW_TRENDS, {});
            }
        })
    }

    selectNodeType (selectedType: string) {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.SELECT_NODE_TYPE, { selectedType });
            }
        })
    }

    expandDrawer () {
        this.userInformation$.subscribe((user) => {
            if (user) {
                    this.analyticsService.pushEvent(this.EVENTS.EXPAND_SIDE_DRAWER, { });
            }
        })
    }

    collapseDrawer () {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.COLLAPSE_SIDE_DRAWER, { });
            }
        })
    }

    search (searchText: string) {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.SEARCH, { searchText });
            }
        })
    }

    export () {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.EXPORT, { });
            }
        })
    }

    viewHistoricalData () {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.VIEW_HISTORICAL_DATA, { });
            }
        })
    }

    viewCompareTrends () {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.VIEW_COMPARE_TRENDS, { });
            }
        })
    }

    selectDateRange (tabName: ComparisonType, dateRange: any) {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.SELECT_DATA_RANGE, { tabName, dateRange});
            }
        })
    }

    selectInterval (tabName: string, interval: any) {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.SELECT_INTERVAL, { tabName, interval});
            }
        })
    }

    channelSelected (channelId: string, displayLabel: string, channelType: string, tabName: string) {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.CHANNEL_SELECTED, { channelId, displayLabel, channelType, tabName});
            }
        })
    }

    compareToPast () {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.COMPARE_TO_PAST, { });
            }
        })
    }

    compareTo (CompareToValue: any) {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.COMPARE_TO, { CompareToValue });
            }
        })
    }

    viewRawData () {
        this.userInformation$.subscribe((user) => {
            if (user) {
                this.analyticsService.pushEvent(this.EVENTS.VIEW_RAW_DATA, { });
            }
        })
    }
}
