import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { Node } from '../models/node.model';
import { NodeTypeService } from './node-type.service';

@Injectable()
export class NodeSelectionService {
  readonly selection$ = new BehaviorSubject<any[]>([]);
  readonly deselect$ = new BehaviorSubject<string>('');
  readonly selectedNodes$ = new BehaviorSubject<Node[]>([]);

  constructor(private nodeTypeService: NodeTypeService) {
    this.nodeTypeService.selectedNodeType$.subscribe(nodeType => {
      if (nodeType) {
        this.deselectAllNodes();
      }
    });
    this.selection$.pipe(debounce(() => timer(600))).subscribe(newSelection => {
      if (this.nodeTypeService.selectedNodeType$.value) {
        this.handleNodeSelection(newSelection);
      }
    });
  }

  handleNodeSelection(newSelection) {
    const typeKey = this.nodeTypeService.selectedNodeType$.value.typeKey;
    let currentNodes = [];

    // Deselect/Remove Nodes
    this.selectedNodes$.getValue().forEach(currentNode => {
      if (newSelection.find(selectedNode => selectedNode.id === currentNode.id)) {
        currentNodes.push(currentNode);
      } else {
        currentNode.destroy();
      }
    });

    // Select/Add Nodes
    const nodesToCreate = [];
    newSelection.forEach(selectedNode => {
      if (!currentNodes.find(currentNode => selectedNode.id === currentNode.id)) {
        nodesToCreate.push(
          new Node(
            selectedNode.id,
            selectedNode.displayLabel,
            selectedNode[typeKey],
            selectedNode.timezone,
            selectedNode,
          ),
        );
      }
    });
    this.selectedNodes$.next(currentNodes.concat(nodesToCreate));
  }

  selectTreeNodes(selectedTreeNodes: any[]): void {
    this.selection$.next(selectedTreeNodes);
  }

  deselectTreeNodes(deselectedNodes: any[]): void {
    deselectedNodes.forEach(node => {
      this.deselect$.next(node);
    })
    let currentSelection = this.selection$.getValue();
    let newSelection = currentSelection.filter(node => {
      return !deselectedNodes.some(desNode => desNode === node.id);
    });
    this.selection$.next(newSelection);
  }

  deselectAllNodes(): void {
    if (this.selection$.value && this.selection$.value.length > 0) {
      this.deselectTreeNodes(this.selection$.value.map(node => node.id));
    }
  }
}
