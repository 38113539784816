<div class="toogle-container" *ngIf="hasSelectedTrends() && hasVerboseResource()">
    <mat-slide-toggle
        [disabled]="!verboseMode"
        [(ngModel)]="filterMetadata"
        labelPosition="before"
        (change)="toggleFilterMetadata()"
        >{{ 'trends.show_anomalies_only' | translate }}</mat-slide-toggle
    >
    <mat-slide-toggle
        class="verbose-toggle"
        [disabled]="!selectedVerboseChannels"
        [(ngModel)]="verboseMode"
        labelPosition="before"
        (change)="toggleMetadata()"
        >{{ 'trends.verbose_mode' | translate }}</mat-slide-toggle
    >
    <ng-template #noToggle><div class="no-toggle"></div></ng-template>
</div>
<div class="table-container" *ngIf="hasSelectedTrends(); else noTrends">
    <table mat-table [dataSource]="tableData" class="border-table">
        <!-- Start Time Column -->
        <ng-container matColumnDef="start-time">
            <th [ngClass]="displayedColumns.length > 3 && 'w-110'" mat-header-cell *matHeaderCellDef>
                {{ 'trends.table.start_time' | translate }}
            </th>
            <td mat-cell *matCellDef="let tableData">{{ tableData[0] }}</td>
        </ng-container>

        <!-- End Time Column -->
        <ng-container matColumnDef="end-time">
            <th [ngClass]="displayedColumns.length > 3 && 'w-110'" mat-header-cell *matHeaderCellDef>
                {{ 'trends.table.end_time' | translate }}
            </th>
            <td mat-cell *matCellDef="let tableData">{{ tableData[1] }}</td>
        </ng-container>

        <!-- Aggregated Trends Columns -->
        <ng-container [matColumnDef]="trend.id" *ngFor="let trend of trends; let i = index">
            <th mat-header-cell *matHeaderCellDef class="trend-column">
                <ng-container *ngIf="!trend.metadata; else metadataHeader">
                    <span class="trend-col-header th-right">{{ trend.channel.displayLabel }}</span>
                    <span class="trend-col-header th-right">{{ trend.node.displayLabel }}</span>

                    <span *ngIf="!trend.error" class="trend-col-header th-right">{{
                        trend.timezone ? trend.timezone.displayLabel : '-'
                    }}</span>
                    <span *ngIf="trend.error" class="trend-col-header loading-error th-right">
                        {{ 'trends.table.failed_to_load' | translate }}
                    </span>
                </ng-container>
                <ng-template #metadataHeader>
                    <span class="trend-col-header">{{ 'trends.table.metadata' | translate }}</span>
                    <span class="trend-col-header">{{ findMetadataAnomalities(i) }}</span>
                </ng-template>
            </th>
            <td
                mat-cell
                *matCellDef="let tableData; let first = first; let ind = index"
                class="{{ !trend.metadata ? 'th-right' : ' metadata-col' }}"
            >
                <ng-container *ngIf="!trend.error || trend.loading; else trendError">
                    <ng-container *ngIf="!loadingTrends() || !first; else loadingData">
                        <ng-container *ngIf="!trend.metadata; else metadata">
                            {{ loadingTrends() ? '' : tableData[i + 2] !== null ? tableData[i + 2] : '-' }}
                        </ng-container>
                        <ng-template #metadata>
                            <span class="metadata-col th-center">
                                <mat-icon
                                    *ngIf="checkMetadataAnomaly(tableData[i + 2]); else normalData"
                                    class="pointer red-icon"
                                    (click)="showAllMetadata(tableData[i + 2])"
                                    ><span class="material-icons">
                                        report_gmailerrorred
                                    </span></mat-icon
                                >
                                <ng-template #normalData>
                                    <ng-container *ngIf="isNotempty(tableData[i + 2])">
                                        <mat-icon class="pointer green-icon" (click)="showAllMetadata(tableData[i + 2])"
                                            >done</mat-icon
                                        >
                                    </ng-container>
                                </ng-template>
                            </span>
                        </ng-template>
                    </ng-container>
                    <ng-template #loadingData>
                        <span class="trend-col-header loading-error">
                            <div class="loading-spinner">
                                <mat-spinner color="primary" diameter="20"></mat-spinner>
                            </div>
                        </span>
                    </ng-template>
                </ng-container>
                <ng-template #trendError>
                    -
                </ng-template>
            </td>
        </ng-container>

        <!-- None Aggreagted Trends Columns -->
        <ng-container [matColumnDef]="trend.id" *ngFor="let trend of noneAggregatedTrends; let i = index">
            <th mat-header-cell *matHeaderCellDef class="trend-column">
                <ng-container *ngIf="!trend.metadata && !trend.timestamp">
                    <span class="trend-col-header th-right">{{ trend.channel.displayLabel }}</span>
                    <span class="trend-col-header th-right">{{ trend.node.displayLabel }}</span>

                    <span *ngIf="!trend.error" class="trend-col-header th-right">{{
                        trend.timezone ? trend.timezone.displayLabel : '-'
                    }}</span>
                    <span *ngIf="trend.error" class="trend-col-header loading-error th-right">
                        {{ 'trends.table.failed_to_load' | translate }}
                    </span>
                </ng-container>
                <ng-container *ngIf="trend.timestamp">
                    <span class="trend-col-header th-right info-msg">{{ 'trends.none_aggregated' | translate }}</span>
                    <span class="trend-col-header th-right info-msg">{{ 'trends.no_affected' | translate }}</span>
                    <span class="trend-col-header th-right">{{ 'trends.table.timestamp' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="trend.metadata">
                    <span class="trend-col-header">{{ 'trends.table.metadata' | translate }}</span>
                    <span class="trend-col-header">{{ findMetadataAnomalities(i) }}</span>
                </ng-container>
            </th>
            <td
                mat-cell
                *matCellDef="let tableData; let first = first; let ind = index"
                class="{{ !trend.metadata ? 'th-right not-aggregated' : ' metadata-col not-aggregated' }}"
            >
                <ng-container *ngIf="!trend.error || trend.loading; else trendError">
                    <ng-container *ngIf="!loadingTrends() || !first; else loadingData">
                        <ng-container *ngIf="!trend.metadata && !trend.timestamp">
                            {{
                                loadingTrends()
                                    ? ''
                                    : tableData[getTableIndex(i)] !== null
                                    ? tableData[getTableIndex(i)]
                                    : '-'
                            }}
                        </ng-container>
                        <ng-container *ngIf="trend.timestamp">
                            <span class="timestamp-col">
                                {{ loadingTrends() ? '' : tableData[getTableIndex(i)] }}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="trend.metadata">
                            <span class="metadata-col th-center">
                                <mat-icon
                                    *ngIf="checkMetadataAnomaly(tableData[getTableIndex(i)]); else normalData"
                                    class="pointer red-icon"
                                    (click)="showAllMetadata(tableData[getTableIndex(i)])"
                                    ><span class="material-icons">
                                        report_gmailerrorred
                                    </span></mat-icon
                                >
                                <ng-template #normalData>
                                    <ng-container *ngIf="isNotempty(tableData[getTableIndex(i)])">
                                        <mat-icon
                                            class="pointer green-icon"
                                            (click)="showAllMetadata(tableData[getTableIndex(i)])"
                                            >done</mat-icon
                                        >
                                    </ng-container>
                                </ng-template>
                            </span>
                        </ng-container>
                    </ng-container>
                    <ng-template #loadingData>
                        <span class="trend-col-header loading-error">
                            <div class="loading-spinner">
                                <mat-spinner color="primary" diameter="20"></mat-spinner>
                            </div>
                        </span>
                    </ng-template>
                </ng-container>
                <ng-template #trendError>
                    -
                </ng-template>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-first-header-row"></tr>
        <tr
            mat-row
            *matRowDef="let row; let even = even; columns: displayedColumns"
            [ngClass]="even ? 'grey-row' : ''"
        ></tr>
    </table>
</div>
<mat-paginator
    [ngClass]="!hasSelectedTrends() || !hasReadings() ? 'hidden' : ''"
    [pageSizeOptions]="[200, 500, 1000]"
></mat-paginator>
<ng-template #noTrends>
    <div class="no-chart">
        <mat-icon>info</mat-icon>
        <div>{{ 'trends.chart.no_trends_selected' | translate }}</div>
    </div>
</ng-template>
