import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ComparisonTypes, ComparisonType } from '../models/comparisonType.model';
import { DeeplinksService } from './deeplinks.service';

@Injectable()
export class ComparisonService {
  readonly selectedComparisonType$ = new BehaviorSubject<ComparisonType>(null);
  readonly selectedPastDateRange$ = new BehaviorSubject<string>("previous");

  constructor(private deeplinkService: DeeplinksService) {
    this.deeplinkService.initialDeeplinks$.subscribe(deeplinks => {
      this.setComparisonType(this.findComparisonType(deeplinks.compare) || ComparisonTypes[0]);
    });
  }

  setComparisonType(comparisonType: ComparisonType) {
    if (ComparisonTypes.includes(comparisonType)) {
      switch (comparisonType.type) {
        case 'trends':
          break;
        case 'past':
          break;
      }
      this.selectedComparisonType$.next(comparisonType);
      this.deeplinkService.updateCompare(comparisonType.type);
    }
  }

  setSelectedPastDateRange(dateRange){
    this.selectedPastDateRange$.next(dateRange);
  }

  findComparisonType(typeId: string): ComparisonType {
    if (typeId) {
      return ComparisonTypes.find(comparisonType => comparisonType.type === typeId);
    }
  }
}
