import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { Permission } from './auth-guard.service';
import * as moment from 'moment-timezone';

const appName = 'TRENDS';

@Injectable()
export class UserService {
  redirectUrl: string;
  user$ = new BehaviorSubject<User>(null);

  readonly appPermissions$: Subject<Permission[]> = new Subject<Permission[]>();
  readonly permissions$: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);

  constructor(private apiService: ApiService) {
    this.getUserDetails();

    this.user$.subscribe(user => {
      if (user) {
        moment.tz.setDefault(user.preferences.default_time_zone);
      }
    });
  }

  /**
   * Get the Permissions for the Markets app that the logged in User has.
   *
   * @returns {Promise<User>}
   */
  async getAppPermissions() {
    const userDetails = await this.apiService.get('user/details');
    const permissions = userDetails && userDetails.permissions[appName] ? userDetails.permissions[appName] : [];
    this.appPermissions$.next(permissions);
    return permissions;
  }

  async getUserDetails() {
    const userDetails = await this.apiService.get('user/details');
    this.user$.next(userDetails);
    this.permissions$.next(userDetails.permissions);
  }

  hasPermission(resource: string, permission: string) {
    const permissions = this.permissions$.getValue();
    return !!(permissions[resource] && permissions[resource].includes(permission));
  }
}
