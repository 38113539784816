import { Component, OnDestroy } from '@angular/core';
import { NodeTypeService } from '../services/node-type.service';
import { NodeType } from '../models/nodeType.model';
import { NodeSelectionService } from '../services/node-selection.service';
import { Node } from '../models/node.model';

@Component({
  selector: 'app-trends-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class NodesCardsComponent implements OnDestroy {
  nodes: Node[];
  selectedNodeType: NodeType;
  subscriptions = [];

  constructor(private nodeSelectionService: NodeSelectionService, private nodeTypeService: NodeTypeService) {
    const nodesSubscription = this.nodeSelectionService.selectedNodes$.subscribe(nodes => {
      this.nodes = nodes;
    });

    const selectedNodeTypeSub = this.nodeTypeService.selectedNodeType$.subscribe(type => {
      this.selectedNodeType = type;
    });

    this.subscriptions = [nodesSubscription, selectedNodeTypeSub];
  }

  unselectNode(node) {
    this.nodeSelectionService.deselectTreeNodes([node.id]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
