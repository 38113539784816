import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GranularityService } from './granularity.service';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment-timezone';

@Injectable()
export class ExportService {
  constructor(private translateService: TranslateService, private granularityService: GranularityService) {}

  exportTrends(summaries) {
    this.exportXLSX(this.summariesTo2DArray(summaries));
  }

  /**
   * e.g.
   *    row1 = blank         blank         AirComp      WindVane
   *    row2 = blank         blank         Elec Demand  Knots
   *    row3 = StartTime     EndTime       EST
   *    row4 = 2/2/19 0:00   2/2/19 0:15   37.02349524  17
   *    row5 = 2/2/19 0:15   2/2/19 0:30   50.23482389  20
   *
   * @param summaries
   * @param noStringifyMetadata
   * @returns [ an array of rows for export ]
   */
  summariesTo2DArray(summaries, noStringifyMetadata?: boolean) {
    let data = [];

    let nodeNames = [];
    let channelNames = [];
    let columnNames = [];

    let remappedReadingsToSpace = [];
    let remappedNotAggregatedReadings = [];

    let notAggregatedName: string[] = [];
    let notAggregatedChannel: string[] = [];
    let notAggregatedTimezone: string[] = [];

    let notAggregatedTrends = false;

    if (summaries.length === 0) {
      console.log('no trends selected - from 2d array');
      return [];
    }
    let selectedGranularityMs = this.granularityService.selectedGranularity$.getValue().duration_in_milliseconds;

    // Add a column for each trend with Channel/Timezone/Name and a aditional one for trends with verbosa data
    summaries.forEach(trend => {
      if (trend.isAggregated) {
        if (trend.readings) {
          nodeNames.push(trend.node.displayLabel || 'N/A');
          channelNames.push(trend.channel.displayLabel);
          columnNames.push(trend.timezone ? trend.timezone.displayLabel : '-');
          remappedReadingsToSpace.push({ reading: trend.readings, verbose: trend.verbose });
        }
        if (trend.verbose) {
          nodeNames.push('');
          channelNames.push('');
          columnNames.push(this.dumbTrans('table.metadata'));
        }
      } else {
        notAggregatedTrends = true;
        notAggregatedName.push(this.dumbTrans('none_aggregated'), trend.node.displayLabel || 'N/A');
        notAggregatedChannel.push(this.dumbTrans('no_affected'), trend.channel.displayLabel);
        notAggregatedTimezone.push(
          this.dumbTrans('table.timestamp'),
          trend.timezone ? trend.timezone.displayLabel : '-',
        );
        remappedNotAggregatedReadings.push({ reading: trend.readings, verbose: trend.verbose });
        if (trend.verbose) {
          notAggregatedName.push('');
          notAggregatedChannel.push('');
          notAggregatedTimezone.push(this.dumbTrans('table.metadata'));
        }
      }
    });

    // row 1 - space names
    nodeNames = nodeNames.length > 0 ? ['', '', ...nodeNames] : [];
    // row 2 - channel names (uom)
    channelNames = channelNames.length > 0 ? ['', '', ...channelNames] : [];
    // row 3 - column names (including timezone above readings)
    columnNames =
      columnNames.length > 0
        ? [this.dumbTrans('exports.csv.start_date'), this.dumbTrans('exports.csv.end_date'), ...columnNames]
        : [];

    data.push([...nodeNames, ...notAggregatedName]);
    data.push([...channelNames, ...notAggregatedChannel]);
    data.push([...columnNames, ...notAggregatedTimezone]);

    let indexWithReadings = -1;
    let numberOfReadings = 0;
    remappedReadingsToSpace.forEach((readings, index) => {
      if (readings.reading.length > numberOfReadings) {
        numberOfReadings = readings.reading.length;
        indexWithReadings = index;
      }
    });

    if (indexWithReadings > -1) {
      remappedReadingsToSpace[indexWithReadings].reading.forEach((reading, readingIndex) => {
        let datetime = moment(reading.timestamp)
          .subtract(selectedGranularityMs, 'milliseconds')
          .format();

        // add timestamps to the row
        let row = [this.formatExportDate(datetime), this.formatExportDate(reading.timestamp)];

        remappedReadingsToSpace.forEach(function(readingsArr) {
          const value = readingsArr?.reading[readingIndex]?.value;
          row.push(value !== null || value !== undefined ? value : '');
          if (readingsArr?.verbose == true) {
            if (noStringifyMetadata) {
              row.push(readingsArr?.reading?.[readingIndex]?.metadata || {});
            } else {
              row.push(JSON.stringify(readingsArr?.reading?.[readingIndex]?.metadata) || '');
            }
          }
        });

        // add the row to the sheet
        data.push(row);
      });
    }

    if (notAggregatedTrends) {
      let blankSpaces = [];
      remappedReadingsToSpace.forEach(trend => {
        blankSpaces.push('');
        if (trend.verbose) {
          blankSpaces.push('');
        }
      });
      if (blankSpaces.length > 0) {
        blankSpaces = [...blankSpaces, '', ''];
      }

      remappedNotAggregatedReadings.forEach((trend, i) => {
        const trendPosition = new Array(i * 2).fill('');
        trend.reading.forEach((reading, ind) => {
          let datetime = moment(reading.timestamp);
          if (data[ind + 3]) {
            data[ind + 3].push(this.formatExportDate(datetime), reading.value);
          } else {
            data[ind + 3] = [...blankSpaces, ...trendPosition, this.formatExportDate(datetime), reading.value];
          }
          if (trend?.verbose == true) {
            if (noStringifyMetadata) {
              data[ind + 3].push(reading?.metadata || {});
            } else {
              data[ind + 3].push(JSON.stringify(reading?.metadata) || '');
            }
          }
        });
      });
    }

    // If no readings add to the table 10 empty rows
    if (data.length < 4) {
      for (let i = 0; i < 10; i++) {
        data.push(data[0].map(e => ''));
      }
    }
    return data;
  }

  /**
   *
   * @param data
   */
  exportXLSX(data) {
    let ws = XLSX.utils.aoa_to_sheet(data);

    Object.keys(ws).forEach(function(cell) {
      if (ws[cell].t === 'n') {
        ws[cell] = { v: ws[cell].v, t: ws[cell].t, z: '###,###,###,##0.000000000' };
      }
    });

    let wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, this.dumbTrans('title'));

    let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    function s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      /*jslint bitwise: true*/
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      /*jslint bitwise: false*/
      return buf;
    }

    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), this.xlsxLabel());
  }

  xlsxLabel() {
    let d = new Date();
    return (
      this.dumbTrans('title') +
      '_' +
      d.getFullYear() +
      this.twoDigits(d.getMonth() + 1) +
      this.twoDigits(d.getDate()) +
      this.twoDigits(d.getHours()) +
      this.twoDigits(d.getMinutes()) +
      '.xlsx'
    );
  }

  twoDigits(n) {
    return (n < 10 ? '0' : '') + n;
  }

  formatExportDate(value) {
    return moment(value).format('L LTS');
  }

  dumbTrans(key) {
    return this.translateService.instant('trends.' + key).replace(/[\[\]&\/\\#,+()$~%.'":*?<>{}]/g, '');
  }
}
