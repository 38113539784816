import { BehaviorSubject } from 'rxjs';
import { Channel } from './channel.model';
import { ReadingsService } from '../services/readings.service';
import { ColorService } from '../services/color.service';
import { InjectorService } from '../services/injector.service';
import { TimezonesService } from '../services/timezones.service';
import { Timezone } from './timezone.model';
import { ReadingsErrorsService } from './../services/readings-error.service';
import {ChartErrorService} from "../services/chart-error.service";
import {TranslateService} from "@ngx-translate/core";

interface Node {
  id: string;
  displayLabel: string;
  type: string;
  timezone: string;
}

export class Trend {
  id: string;
  channel: Channel;
  node: Node = {
    id: '',
    displayLabel: '',
    type: '',
    timezone: '',
  };
  type: string = '';
  nodeId: string;
  color;
  loading: boolean = false;
  error: any;
  readings: any[] = null;
  seriesOptions: any;
  compareToPast: boolean = false;
  timezone: Timezone;
  verbose: boolean = false;
  hasValues: boolean = false;
  readingsGranularity: string;
  isAggregated: boolean;
  valueToCompare: string = null;

  readonly readings$ = new BehaviorSubject<any[]>(null);
  readonly summaryData$ = new BehaviorSubject<object[]>([]);

  private colorService: ColorService;
  private readingsService: ReadingsService;
  private timezonesService: TimezonesService;
  private readingsErrors: ReadingsErrorsService;
  private errorService: ChartErrorService;
  private translateService: TranslateService;

  constructor(node: Node, channel: Channel, compareToPast?: boolean) {
    this.colorService = InjectorService.injector.get(ColorService);
    this.readingsService = InjectorService.injector.get(ReadingsService);
    this.timezonesService = InjectorService.injector.get(TimezonesService);
    this.readingsErrors = InjectorService.injector.get(ReadingsErrorsService);
    this.errorService = InjectorService.injector.get(ChartErrorService);
    this.translateService = InjectorService.injector.get(TranslateService);

    this.nodeId = node.id;
    this.node.type = node.type;
    this.type = node.type;
    this.channel = channel;

    if (channel.registration != undefined) {
      this.nodeId = channel.registration.id;
      this.type = 'Registration';
    }

    this.id = compareToPast ? `${this.nodeId}_${channel.id}_past` : `${this.nodeId}_${channel.id}`;
    this.color = this.colorService.getColor();
    this.channel.base_granularity = channel.base_granularity;
    this.isAggregated = channel.aggregationType !== 'NONE';
    this.node.id = node.id;
    this.node.displayLabel = compareToPast ? `[PAST] ${node.displayLabel}` : node.displayLabel;
    this.node.timezone = node.timezone;
    this.timezone = this.timezonesService.findTimezone(node.timezone);
    this.compareToPast = compareToPast;

    let me = this;
    if (!this.readings) {
      setTimeout(function() {
        me.getReadings();
      }, 100);
    }
  }

  setSummaryData(summaryData: object[]): void {
    this.summaryData$.next(summaryData);
  }

  async getReadings() {
    let readings;
    this.loading = true;
    this.error = null;
    try {
      readings = await this.readingsService.getReadings(
        this.nodeId,
        this.channel.id,
        this.type,
        this.channel.pointType,
        this.compareToPast,
        this.channel.base_granularity,
        this.channel.aggregationType == 'NONE',
      );
    } catch (err) {
      console.log(err);
      this.loading = false;
      this.error = err.error;
      this.errorService.setError(this.translateService.instant('trends.summary.data_timeout'));
      this.readings$.next([]);
      this.readingsErrors.error$.next({
        node: this.node.displayLabel,
        channel: this.channel.displayLabel,
        id: this.id,
      });
    }
    this.loading = false;
    if (readings && readings[this.nodeId] && readings[this.nodeId][this.channel.id].hasValues) {
      this.verbose = readings[this.nodeId][this.channel.id].verbose;
      this.hasValues = readings[this.nodeId][this.channel.id].hasValues;
      this.readingsGranularity = readings[this.nodeId][this.channel.id].granularity;
      readings = readings[this.nodeId][this.channel.id].readings;
    } else {
      readings = [];
      this.hasValues = false;
    }
    this.readings = readings;
    this.readings$.next(readings);
  }

  destroy() {
    this.colorService.recycleColor(this.color);
    this.readings$.complete();
    this.summaryData$.complete();
  }
}
