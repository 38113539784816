import { Trend } from '../../../models/trend.model';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary-line',
  templateUrl: './summary-line.component.html',
  styleUrls: ['./summary-line.component.scss'],
})
export class SummaryLineComponent implements OnInit, OnDestroy {
  max: number;
  min: number;
  sum: number;
  avg: number;

  starting = false;
  summaryData: any = [];

  @Input() nodeId: string;
  @Input() trend: Trend;
  @Input() channelId: string;
  @Input() pointType: string;

  summarySubscription: any;

  constructor() {}

  ngOnInit() {
    this.summarySubscription = this.trend.summaryData$.subscribe(summaryData => {
      if (summaryData.length > 0) {
        this.starting = true;
        this.summaryData = summaryData;
        this.getValuesFromSummary(this.summaryData);
      }
    });
  }

  getValuesFromSummary(summary) {
    if (summary) {
      this.min = this.summaryData[0]?.value;
      this.max = this.summaryData[1]?.value;
      this.avg = this.summaryData[2]?.value;
      this.sum = this.summaryData[3]?.value;
    }
  }

  getAvgRelativePosition() {
    if (this.summaryData && this.summaryData.length > 0) {
      const min = this.summaryData[0]?.parsedValue;
      const max = this.summaryData[1]?.parsedValue;
      const avg = this.summaryData[2]?.parsedValue;
      return ((avg - min) * 100) / (max - min);
    }
    return 0;
  }

  identicalValues() {
    return this.min == this.max && this.min == this.avg;
  }

  isBaselinePoint() {
    return this.pointType === 'baseline';
  }

  getLowHalfSize() {
    const position = this.getAvgRelativePosition();
    return `calc(${position}% - 15px)`;
  }

  getHighHalfSize() {
    const position = this.getAvgRelativePosition();
    return `calc(${100 - position}% - 15px)`;
  }

  getAvgPosition() {
    const position = this.getAvgRelativePosition();
    if (position > 50) {
      return `translateX(-${(position - 50) * 2}%)`;
    }
  }

  ngOnDestroy(): void {
    this.summarySubscription.unsubscribe();
  }
}
