import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingService } from './loading.service';

@Injectable()
export class LoadingHttpInterceptor implements HttpInterceptor {
  private static numberOfRequests: number = 0;

  constructor(private loading: LoadingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loading.loading$.next(true);
    LoadingHttpInterceptor.numberOfRequests++;
    return next.handle(request).pipe(
      tap(value => {
        if (value.type === HttpEventType.Response) {
          this.checkRequestResponses();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.log({ error });
        this.checkRequestResponses();
        return throwError(error);
      }),
    );
  }

  checkRequestResponses() {
    LoadingHttpInterceptor.numberOfRequests--;
    if (LoadingHttpInterceptor.numberOfRequests <= 0) {
      this.loading.loading$.next(false);
      LoadingHttpInterceptor.numberOfRequests = 0;
    }
  }
}
