import { Environment } from './environment.interface';
export { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  env: 'dev',
  brands: {
    enelx: {
      baseUrl: '/',
    },
  },
  cdn: 'https://cdn.dev-apps.enelx.net',
  defaults: {
    brand: 'enelx',
    locale: 'en-us',
  },
};
