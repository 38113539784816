<mat-form-field appearance="outline" floatLabel="always">
    <mat-label [translate]="'trends.interval.label'"></mat-label>
    <mat-select
        [value]="selectedGranularity"
        (selectionChange)="selectGranularity($event.value)"
        [disabled]="disableSelector"
        data-automation="interval-selector"
    >
        <mat-option
            *ngFor="let gran of filteredGranularities()"
            [value]="gran"
            [disabled]="gran.duration_in_milliseconds < minReportingInterval"
            data-automation="interval-option"
        >
            {{ gran.display_label | translate }}
        </mat-option>
    </mat-select>
</mat-form-field>
