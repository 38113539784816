import { GranularityService } from 'src/app/services/granularity.service';
import { AfterViewInit, Component } from '@angular/core';
import { Summary } from '../models/summary.model';
import { Trend } from '../models/trend.model';
import { ComparisonType } from '../models/comparisonType.model';
import { Daterange } from '../models/daterange.model';
import { TrendsService } from '../services/trends.service';
import { ChartService } from '../services/chart.service';
import { SummaryService } from '../services/summary.service';
import { DateRangeService } from '../services/date-range.service';
import { MathService } from '../services/math.service';
import { ComparisonService } from '../services/comparison.service';
import { LoadingService } from '../services/loading.service';
import { CompareToPastService } from 'src/app/services/compare-to-past.service';
import { TranslateService } from '@ngx-translate/core';

import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';

import * as moment from 'moment-timezone';
import { debounce } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements AfterViewInit {
  Highcharts: typeof Highcharts = Highcharts;
  export = HC_exporting(Highcharts);
  userBrand = 'ENELX';
  loading = true;
  hasData = false;
  trends: Trend[] = [];
  summaries: Summary[] = [];
  selectedDateRange: Daterange;
  selectedComparisonType: ComparisonType;
  chart: Highcharts.Chart;
  update = false;
  compareToPast: string = 'previous';
  xAxisFormatter = function () {
    let tickHours =
      Array.isArray(this.axis.tickPositions) && this.axis.tickPositions.length > 1
        ? (this.axis.tickPositions[1] - this.axis.tickPositions[0]) / (1000 * 60 * 60)
        : 0;
    if (tickHours >= 15 * 24) {
      // >= 15 days (half month)   '02/08/2018'
      return moment(this.value).format('l');
    }
    if (tickHours >= 12 && tickHours < 15 * 24) {
      // >= 12 hours half day 'February 8, 2018'
      return moment(this.value).format('ll');
    }
    return moment(this.value).format('lll'); // less than 12 hours 'February 8, 2018 4:38 PM'
  };

  exportChart(options) {
    const filename = `${moment().local().format('YYYY-MM-DD_HHmmss')}_${this.userBrand}`;
    this.chart.exportChart({ ...this.options.exporting, ...options, filename }, { ...this.options.exporting });
  }

  options: Highcharts.Options = {
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
          symbol: 'menuball',
          menuItems: [
            'viewFullscreen', // View in full screen
            'separator', // Adds a separator line in the menu
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
          ],
        },
      },
      menuItemDefinitions: {
        downloadPNG: {
          onclick: () => {
            this.exportChart({ type: 'image/png' });
          },
        },
        downloadJPEG: {
          onclick: () => {
            this.exportChart({ type: 'image/jpeg' });
          },
        },
        downloadPDF: {
          onclick: () => {
            this.exportChart({ type: 'application/pdf' });
          },
        },
        downloadSVG: {
          onclick: () => {
            this.exportChart({ type: 'image/svg+xml' });
          },
        },
      },
      enabled: true,
      filename: `${moment().format('YYYY-MM-DD_HHmmss')}_${this.userBrand}`,
      sourceWidth: 1280,
      sourceHeight: 500,
      chartOptions: {
        // specific options for the exported image
        legend: {
          enabled: true,
          title: {
            style: {
              fontSize: '10px',
              fontFamily: 'Arial',
            },
          },
          itemStyle: {
            fontSize: '10px',
            fontFamily: 'Arial',
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              style: {
                fontSize: '10px',
                fontFamily: 'Arial',
              },
            },
          },
        },
        xAxis: {
          lineWidth: 1,
          labels: {
            enabled: true,
            style: {
              fontSize: '10px',
              fontFamily: 'Arial',
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            labels: {
              enabled: true,
              style: {
                fontSize: '10px',
                fontFamily: 'Arial',
              },
            },
            title: {
              style: {
                fontSize: '10px',
                fontFamily: 'Arial',
              },
            },
          },
          {
            lineWidth: 1,
            labels: {
              enabled: true,
              style: {
                fontSize: '10px',
                fontFamily: 'Arial',
              },
            },
            title: {
              style: {
                fontSize: '10px',
                fontFamily: 'Arial',
              },
            },
          },
          {
            lineWidth: 1,
            labels: {
              enabled: true,
              style: {
                fontSize: '10px',
                fontFamily: 'Arial',
              },
            },
            title: {
              style: {
                fontSize: '10px',
                fontFamily: 'Arial',
              },
            },
          },
          {
            lineWidth: 1,
            labels: {
              enabled: true,
              style: {
                fontSize: '10px',
                fontFamily: 'Arial',
              },
            },
            title: {
              style: {
                fontSize: '10px',
                fontFamily: 'Arial',
              },
            },
          },
        ],
      },
      scale: 2,
      fallbackToExportServer: false,
    },
    chart: {
      type: 'line',
      style: {
        fontFamily: 'Roobert',
      }
    },
    title: {
      text: 'I',
      align: 'left',
      margin: 25,
      style: {
        color: '#FFFFFF',
        fontSize: '14px',
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: [
      {
        lineWidth: 0.5,
        labels: {
          enabled: true,
          style: {
            fontSize: '10px',
            fontFamily: 'Arial',
          },
        },
      },
    ],
    xAxis: [
      {
        type: 'datetime',
        crosshair: true,
        tickWidth: 1,
        tickLength: 6,
        plotBands: [],
        labels: {
          autoRotation: [-45],
          style: {
            color: '#999999',
            fontSize: '12px',
          },
          formatter: this.xAxisFormatter,
        },
      },
    ],
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        turboThreshold: 3000,
      },
    },
    legend: {
      itemStyle: {
        color: '#000103',
        fontSize: '16px',
        lineHeight: '18px',
      },
      useHTML:true,
      labelFormatter: function() {
        return `<div><span class="tooltip-legend">${this.name}</span><span class="tooltip-full">${this.options['fullName']}</span></div>`
      }
    },
    series: [],
    tooltip: {
      enabled:true,
      shared: true,
      useHTML: true,
      shadow: false,
      borderColor: '#BBBBBB',
    },
  };

  constructor(
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private trendsService: TrendsService,
    private chartsService: ChartService,
    private summaryService: SummaryService,
    private dateRangeService: DateRangeService,
    private comparisonService: ComparisonService,
    private mathService: MathService,
    private granularityService: GranularityService,
    private compareToPastService: CompareToPastService,
  ) {
    this.granularityService.getAllGranularities();
    this.loadingService.hasTrendData$.subscribe((hasData) => {
      this.hasData = hasData;
      if (this.chart) {
        this.showDownloadMenu(hasData);
      }
    });
    this.loadingService.loading$.subscribe((loading) => {
      this.loading = loading;
      if (this.chart) {
        this.showDownloadMenu(this.hasData);
      }
    });

    this.trendsService.trends$.pipe(debounce(() => timer(800))).subscribe((trends) => {
      this.trends = trends;
    });

    this.summaryService.summaries$.subscribe((summaries) => {
      this.summaries = summaries;
    });

    this.dateRangeService.selectedDateRange$.subscribe((dateRange) => {
      this.selectedDateRange = dateRange;
    });

    this.comparisonService.selectedComparisonType$.subscribe((comparisonType) => {
      this.selectedComparisonType = comparisonType;
    });

    this.compareToPastService.selectedCompareToPast$.subscribe((compareToPast) => {
      if (compareToPast) {
        this.compareToPast = compareToPast;
      }
    });

    this.translateService.get('trends.chart.download_png').subscribe(() => {
      const exporting = {
        menuItemDefinitions: {
          viewFullscreen: {
            text: this.translateService.instant('trends.chart.view_full_screen'),
          },
          downloadPNG: {
            text: this.translateService.instant('trends.chart.download_png'),
          },
          downloadJPEG: {
            text: this.translateService.instant('trends.chart.download_jpeg'),
          },
          downloadPDF: {
            text: this.translateService.instant('trends.chart.download_pdf'),
          },
          downloadSVG: {
            text: this.translateService.instant('trends.chart.download_svg'),
          },
        },
      };

      this.chart.update({
        exporting,
      });
    });
  }

  ngAfterViewInit() {
    const me = this;
    this.chart.options.tooltip.formatter = function () {
      let highChartPoints = this.points;
      let coreHtml = '';
      let compareToPast = me.selectedComparisonType.type === 'past';
      let tooltip = '';
      let compareDate = me.calculatePreviousViewDate(moment(this.x));
      let dateHtml =
        moment(this.x).format('LL') + ' ' + moment(this.x).format('LTS') + ' ' + moment(this.x).format('zz');

      let compDateHtml = compareDate.format('LL') + ' ' + compareDate.format('LTS') + ' ' + compareDate.format('zz');

      if (me.summaries !== null && me.summaries.length > 0 && Array.isArray(me.trends)) {
        let html = '';

        coreHtml = me.summaries
          .map((channel) => {
            if (channel.hasReadings) {
              let valueStr;
              channel.trends.forEach(function (trend) {
                if (trend.compareToPast) {
                  compareToPast = true;
                }
              });
              html = `<div class="tooltip-channel-group-${channel.channelId}">`;
              if (!compareToPast) {
                html += `<h6 class="tooltip-channel-header tooltip-title-${channel.channelId}" style="font-size: 14px; padding-left: 2px;">${channel.channelDisplayLabel}</h6>`;
                html += '<table style="padding:0;" width="100%">';
              }

              channel.trends.forEach(function (trend) {
                let series = null;
                highChartPoints.forEach(function (highChartPoint) {
                  if (
                    highChartPoint.series.options.id ===
                    (trend.compareToPast
                      ? `${trend.nodeId}_${trend.channel.id}_past`
                      : `${trend.nodeId}_${trend.channel.id}`)
                  ) {
                    series = highChartPoint;
                  }
                });

                if (series !== null) {
                  const isPastSeries = series.series.options.id.includes('_past');
                  const compareHtml = isPastSeries ? compDateHtml : dateHtml;

                  let value = series.y;
                  valueStr = me.hasIntl() ? me.mathService.formatNumber(value) : value;

                  if (compareToPast) {
                    if (isPastSeries) {
                      html += '<br class="tooltip-break">';
                    }
                    html +=
                      '<table class="tooltip-channel-table" style="padding:0;" width="100%">' +
                      `<tr class="tooltip-node tooltip-compare-date" data-node="${trend.node.displayLabel}">` +
                      `<td class="ellipsis">${compareHtml}</td>` +
                      `</tr>` +
                      '<tr>' +
                      '<td class="ellipsis">' +
                      `<i class="fa fa-circle tooltip-compare-icon" style="color:${series.color};"></i>` +
                      trend.channel.displayLabel +
                      '</td>' +
                      `<td class="tooltip-compare-value" style="color:${series.color}; text-align: right;display:inline-block;">` +
                      `${valueStr} ${trend.channel.uomId}` +
                      '</td>' +
                      '</tr>' +
                      '</table>';
                  } else {
                    html +=
                      `<tr class="tooltip-node" data-node-label="${trend.node.displayLabel}">` +
                      `<td class="tooltip-node-title"><div class="ellipsis">${trend.node.displayLabel}</div></td>` +
                      `<td class="tooltip-value" style="color:${series.color}; text-align: right">` +
                      `${valueStr} ${channel.uomDisplayLabel}` +
                      '</td>' +
                      '</tr>';
                  }
                }
              });

              html += '</table>';
              html += '</div>';
              return html;
            }
          })
          .join('');
      }

      if (!compareToPast) {
        tooltip +=
          '<div style="min-width: 320px;max-width: 330px;">' +
          '<span class="tooltip-date" style="font-size: 14px; padding-left: 2px; line-height: 20px; color: #313B49;">' +
          dateHtml +
          '</span>';
      }
      return tooltip + coreHtml + '</div>';
    };
  }

  getChartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
    this.chartsService.chart = chart;
  }

  calculatePreviousViewDate(date) {
    switch (this.compareToPast) {
      case 'week':
      case 'month':
      case 'year':
        return date.subtract(1, 'year');
      default:
        const range = moment(this.selectedDateRange.end).diff(moment(this.selectedDateRange.start), 'days');
        return date.subtract(range >= 1 ? range + 1 : 1, 'day');
    }
  }

  hasIntl() {
    return typeof Intl === 'object' && typeof Intl.NumberFormat === 'function';
  }

  showDownloadMenu(show) {
    this.chart.update({
      exporting: {
        buttons: {
          contextButton: {
            enabled: show && !this.loading,
          },
        },
      },
    });
  }
}
