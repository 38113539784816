<div class="chart-container" [class.no-trends]="trends.length <= 0">
    <highcharts-chart
        id="trends-chart"
        [Highcharts]="Highcharts"
        [options]="options"
        [(update)]="update"
        (chartInstance)="getChartInstance($event)"
        style="width: 100%; height: 450px; display: block;"
    ></highcharts-chart>
    <div class="no-chart">
        <mat-icon>info</mat-icon>
        <div>{{ 'trends.chart.no_trends_selected' | translate }}</div>
    </div>
</div>
<app-trends-cards></app-trends-cards>
