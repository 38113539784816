import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

export enum Permission {
  create = 'CREATE',
  read = 'READ',
  update = 'UPDATE',
  delete = 'DELETE',
}

@Injectable()
export class AuthGuard implements CanActivate {
  private static hasPermission(permissions, requiredPermissions: Permission[]): boolean {
    if (!requiredPermissions || requiredPermissions.length === 0) {
      return false;
    }
    if (permissions.length === 0) {
      return false;
    }

    return requiredPermissions.every((permission) => permissions.includes(permission));
  }

  readonly forbiddenPath = '/forbidden/index.html';
  private errorType: string;

  constructor(private userService: UserService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const { requiredPermissions } = route.data;
    const permissions = await this.userService.getAppPermissions();

    if (permissions.length === 0 || !AuthGuard.hasPermission(permissions, requiredPermissions)) {
      document.location.href = document.location.origin + this.forbiddenPath + '?type=' + this.errorType;
      return false;
    } else {
      return true;
    }
  }
}
