<div class="chart-controls-bar">
    <div class="chart-control date-range-control">
        <app-daterange-selector></app-daterange-selector>
    </div>
    <div class="chart-control granularity-control">
        <app-granularity-selector></app-granularity-selector>
    </div>
    <div class="chart-control past-control" *ngIf="selectedComparisonType.type === 'past'">
        <div class="chart-control-heading" translate=""></div>
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label [translate]="'trends.daterange.compare_to.label'"></mat-label>
            <mat-select value="previous" (selectionChange)="selectCompareTo($event.value)">
                <mat-option value="previous">
                    <span [translate]="'trends.daterange.previous_date_range'"></span>
                </mat-option>
                <mat-option value="year">
                    <span [translate]="'trends.daterange.range_last_year'"></span>
                </mat-option>
                <mat-option value="week">
                    <span [translate]="'trends.daterange.week_last_year'"></span>
                </mat-option>
                <mat-option value="month">
                    <span [translate]="'trends.daterange.month_last_year'"></span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="chart-control date-range-control" *ngIf="isRefreshAllowed">
        <mat-checkbox [(ngModel)]="autoRefresh" (change)="toggleAutoRefresh($event.checked)">{{
            'trends.chart.auto_refresh' | translate
        }}</mat-checkbox>
    </div>
</div>
