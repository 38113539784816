import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { TrendsService } from './trends.service';

@Injectable()
export class LoadingService {
  currentlyLoading = true;
  readonly loading$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  readonly hasTrendData$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private trendsService: TrendsService) {
    this.loading$.pipe(debounce(() => timer(500))).subscribe(loading => {
      this.currentlyLoading = loading;
      if (!loading) {
        this.hasTrendData$.next(
          trendsService.trends$.getValue().some(trend => trend.readings && trend.readings.length > 0),
        );
      }
    });
    this.trendsService.trends$.pipe(debounce(() => timer(800))).subscribe(trends => {
      if (!this.currentlyLoading) {
        this.hasTrendData$.next(
          trendsService.trends$.getValue().some(trend => trend.readings && trend.readings.length > 0),
        );
      }
    });
  }
}
