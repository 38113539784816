/* * * ANGULAR * * */
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthHttpInterceptor } from './services/auth.interceptor';
import { MockHttpInterceptor } from './services/mock.interceptor';
import { LoadingHttpInterceptor } from './services/loading.interceptor';

/* * * 3RD PARTY * * */
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HighchartsChartModule } from 'highcharts-angular';

/* * * SHARED * * */
import { NgxDeeplinkerModule } from 'ngx-deeplinker';
import { AnalyticsService, CookieService as NgxCookieService, NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';

import { CookieService } from './services/cookie.service';
import { I18nService } from './services/i18n.service';
import { UserService } from './services/user.service';
import { TrendsApiService } from './services/trends-api.service';

/* * * SERVICES * * */
import { NodeSelectionService } from './services/node-selection.service';
import { TrendsService } from './services/trends.service';
import { ChannelsService } from './services/channels.service';
import { ChartService } from './services/chart.service';
import { GranularityService } from './services/granularity.service';
import { DateRangeService } from './services/date-range.service';
import { SummaryService } from './services/summary.service';

import { NodeService } from './services/node.service';
import { NodeTypeService } from './services/node-type.service';
import { ApiService } from './services/api.service';
import { DeeplinksService } from './services/deeplinks.service';
import { TimezonesService } from './services/timezones.service';
import { ReadingsService } from './services/readings.service';
import { ColorService } from './services/color.service';
import { InjectorService } from './services/injector.service';
import { ComparisonService } from './services/comparison.service';
import { ExportService } from './services/export.service';
import { MathService } from './services/math.service';
import { LoadingService } from './services/loading.service';
import { NavService } from './services/global-nav.service';
import { ReadingsErrorsService } from './services/readings-error.service';
import { MixPanelService } from './services/mixpanel.service';
import { AutoRefreshService } from './services/auto-refresh.service';

/* * * COMPONENTS * * */
import { NgxGlobalContactFooterModule } from "ngx-global-contact-footer";
import { GlobalNavModule } from 'ngx-global-nav';
import { TreeModule } from 'enel-tree/dist/enel-tree';
import { NgxDatepickerModule } from 'ngx-datepicker';

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';

import { AppComponent } from './app.component';
import { NodeSelectorComponent } from './node-selector/node-selector.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ChartComponent } from './chart/chart.component';
import { ChartTabsComponent } from './chart/chart-tabs/chart-tabs.component';
import { ChartControlsComponent } from './chart/chart-controls/chart-controls.component';
import { GranularitySelectorComponent } from './granularity-selector/granularity-selector.component';
import { DaterangeSelectorComponent } from './daterange-selector/daterange-selector.component';
import { ExportButtonComponent } from './export-button/export-button.component';
import { HistoricalDataComponent } from './historical-data-button/historical-data.component';
import { RawDataTableComponent } from './raw-data-table/raw-data-table.component';
import { RawDataTableMessagesComponent } from './raw-data-table/raw-data-table-messages/raw-data-table-messages.component';
import { MetadataDialog } from './raw-data-table/metadata-dialog/metadata-dialog.component';
import { NodesCardsComponent } from './cards/cards.component';
import { NodeCardComponent } from './cards/card/card.component';
import { SummaryLineComponent } from './cards/card/summary-line/summary-line.component';

import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import localePl from '@angular/common/locales/pl';
import localeIt from '@angular/common/locales/it';
import localeZh from '@angular/common/locales/zh';
import localeEnCa from '@angular/common/locales/en-CA';
import localeEnAu from '@angular/common/locales/en-AU';
import localeNbNo from '@angular/common/locales/nb';
import { CompareToPastService } from './services/compare-to-past.service';
import {ChartErrorComponent} from "./chart/chart-error/chart-error.component";
import {ChartErrorService} from "./services/chart-error.service";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";

registerLocaleData(localeJa, 'ja-JP');
registerLocaleData(localePl, 'pl-PL');
registerLocaleData(localeIt, 'it-IT');
registerLocaleData(localeZh, 'zh-TW');
registerLocaleData(localeEnAu, 'en-AU');
registerLocaleData(localeEnCa, 'en-CA');
registerLocaleData(localeNbNo, 'nb-NO');

export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http, `${environment.cdn}/l10n/trends/v2/`, '.json');
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    NodeSelectorComponent,
    ChartComponent,
    ChartTabsComponent,
    ChartControlsComponent,
    ChartErrorComponent,
    GranularitySelectorComponent,
    DaterangeSelectorComponent,
    ExportButtonComponent,
    HistoricalDataComponent,
    RawDataTableComponent,
    RawDataTableMessagesComponent,
    MetadataDialog,
    NodesCardsComponent,
    NodeCardComponent,
    SummaryLineComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HighchartsChartModule,
    FormsModule,
    TreeModule,
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    NgxGlobalContactFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
    MaterialModule,
    MatDialogModule,
    NgxDeeplinkerModule,
    ReactiveFormsModule,
    RouterModule.forRoot([]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxDatepickerModule,
  ],
  providers: [
    AnalyticsService,
    ApiService,
    ChannelsService,
    ChartService,
    ChartErrorService,
    ColorService,
    ComparisonService,
    CookieService,
    DateRangeService,
    DeeplinksService,
    ExportService,
    GranularityService,
    HttpClient,
    I18nService,
    LoadingService,
    MathService,
    MatDialog,
    MixPanelService,
    NavService,
    NgxCookieService,
    TrendsApiService,
    NodeService,
    NodeTypeService,
    CompareToPastService,
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingHttpInterceptor, multi: true },
    ReadingsService,
    NodeSelectionService,
    SummaryService,
    TranslateService,
    TimezonesService,
    TrendsService,
    UserService,
    { provide: NOC_ANALYTICS, useValue: analytics },
    UserService,
    ReadingsErrorsService,
    AutoRefreshService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    // Create global Service Injector.
    InjectorService.injector = this.injector;
  }
}
