import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'metadata-dialog',
  templateUrl: './metadata-dialog.component.html',
  styleUrls: ['./metadata-dialog.component.scss'],
})
export class MetadataDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  isNumber(value) {
    return typeof value === 'number';
  }
}
