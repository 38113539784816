import { Component, OnInit } from '@angular/core';
import { CompareToPastService } from 'src/app/services/compare-to-past.service';
import { ComparisonService } from '../../services/comparison.service';
import { MixPanelService } from '../../services/mixpanel.service';
import { AutoRefreshService } from 'src/app/services/auto-refresh.service';
@Component({
  selector: 'app-chart-controls',
  templateUrl: './chart-controls.component.html',
  styleUrls: ['./chart-controls.component.scss'],
})
export class ChartControlsComponent implements OnInit {
  isInit = true;
  selectedComparisonType;
  isRefreshAllowed = false;
  autoRefresh;

  constructor(
    private comparisonService: ComparisonService,
    private compareToPastService: CompareToPastService,
    private autoRefreshService: AutoRefreshService,
    private mixpanelService: MixPanelService
  ) {}

  async ngOnInit() {
    this.comparisonService.selectedComparisonType$.subscribe((comparisonType) => {
      this.selectedComparisonType = comparisonType;
    });
    this.autoRefreshService.allowRefresh$.subscribe((value) => {
      this.isRefreshAllowed = value;
    });
    this.autoRefresh = this.autoRefreshService.autoRefreshEnabled$.getValue();
  }
  selectCompareTo(CompareValue) {
    this.mixpanelService.compareTo(CompareValue)
    this.comparisonService.setSelectedPastDateRange(CompareValue);
  }

  toggleAutoRefresh(value) {
    this.autoRefresh = value;
    this.autoRefreshService.setAutoRefresh(value);
  }
}
