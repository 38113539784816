export interface ComparisonType {
  type: string;
  label: string;
  multiNode: boolean;
  multiChannel: boolean;
}

export const ComparisonTypes: ComparisonType[] = [
  {
    type: 'trends',
    label: 'trends.compare_trends',
    multiNode: true,
    multiChannel: true,
  },
  {
    type: 'past',
    label: 'trends.compare_to_past',
    multiNode: false,
    multiChannel: false,
  },
  {
    type: 'raw',
    label: 'trends.raw_data',
    multiNode: true,
    multiChannel: true,
  },
];
