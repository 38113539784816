import { AutoRefreshService } from 'src/app/services/auto-refresh.service';
import { Injectable } from '@angular/core';
import { Trend } from '../models/trend.model';
import * as Highcharts from 'highcharts';
import { SeriesOptionsType } from 'highcharts';
import * as moment from 'moment-timezone';
import { DateRangeService } from './date-range.service';
import { GranularityService } from './granularity.service';
import { Granularity } from '../models/granularity.model';
import { Daterange } from '../models/daterange.model';
import { TrendsService } from './trends.service';
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import { combineLatest, timer } from 'rxjs';
import { ComparisonService } from '../services/comparison.service';
import { CompareToPastService } from '../services/compare-to-past.service';

@Injectable()
export class ChartService {
  chart: Highcharts.Chart;
  selectedGranularity: Granularity;
  selectedDateRange: Daterange;
  trends: Trend[] = [];

  constructor(
    private daterangeService: DateRangeService,
    private granularityService: GranularityService,
    private trendsService: TrendsService,
    private comparisonService: ComparisonService,
    private compareToPastService: CompareToPastService,
    private autoRefreshService: AutoRefreshService,
  ) {
    combineLatest([
      this.daterangeService.selectedDateRange$,
      this.granularityService.selectedGranularity$,
      this.comparisonService.selectedPastDateRange$,
      this.autoRefreshService.forceRefresh$,
    ])
      .pipe(
        debounce(() => timer(500)),
        distinctUntilChanged((pre, cur) => JSON.stringify(pre) === JSON.stringify(cur)),
      )
      .subscribe(([daterange, granularity, comparisonType]) => {
        if (!daterange) {
          return;
        }
        this.selectedDateRange = daterange;
        this.selectedGranularity = granularity;
        if (this.comparisonService.selectedComparisonType$.value.type === 'past') {
          this.compareToPastService.calculatePastDateRange();
        }
        this.highlightWeekends();
        this.resetChart();
        this.trends.forEach(async (trend) => {
          trend.getReadings();
        });
      });

    this.trendsService.trends$.pipe(debounce(() => timer(800))).subscribe((trends) => {
      this.handleTrendSelection(trends);
    });
  }

  handleTrendSelection(trends) {
    const trendsToRemove = this.trends.filter((trend) => trends.indexOf(trend) === -1);
    this.removeTrendsFromChart(trendsToRemove);

    const newTrends = trends.filter((trend) => this.trends.indexOf(trend) === -1);
    this.trends = trends;
    newTrends.forEach((trend) => {
      trend.readings$.subscribe((readings) => {
        this.setChartLoading();
        if (this.trends.indexOf(trend) !== -1 && readings && readings.length > 0) {
          trend.seriesOptions = this.getSeriesOptionsForTrend(trend);
          this.removeTrendFromChart(trend.id);
          this.addTrendToChart(trend);
        }
      });
    });
    return this.trends;
  }

  addTrendToChart(trend: Trend) {
    const yAxisExists = this.chart.axes.find((axis) => {
      return !axis.isXAxis && axis.userOptions.title && trend.channel.uomId === axis.userOptions.id;
    });

    if (!yAxisExists) {
      this.chart.addAxis({
        id: trend.channel.uomId,
        title: {
          text: trend.channel.uomDisplayLabel,
        },
        showEmpty: false,
        opposite: this.chart.axes.length >= 3,
      });
    }
    this.chart.addSeries(trend.seriesOptions, true, true);
  }

  removeTrendFromChart(trendId: string) {
    const trendSeries = this.chart.series.find((series) => series.options.id === trendId);
    if (trendSeries) {
      trendSeries.remove(true);
    }
    // this.updateYAxis();
  }

  removeTrendsFromChart(trends: Trend[]) {
    if (trends && trends.length) {
      trends.forEach((trend) => {
        this.removeTrendFromChart(trend.id);
      });
    }
  }

  highlightWeekends() {
    let start = moment(this.daterangeService.selectedDateRange$.value.start);
    const end = moment(this.daterangeService.selectedDateRange$.value.end);

    const isLessThanAyear = Math.abs(end.diff(start, 'days')) < 364;
    if (this.chart && isLessThanAyear) {
      let weekends = [];
      while (start.isBefore(end)) {
        if (start.day() === 0 || start.day() === 6) {
          weekends.push({
            color: '#F4F4F6',
            from: start.unix() * 1000,
            to: moment(start).add(1, 'day').unix() * 1000,
            id: 'weekend-' + weekends.length,
          });
        }
        start = start.add(1, 'day');
      }
      this.chart.update({ xAxis: { plotBands: weekends } }, true, true, true);
    }
  }

  getSeriesOptionsForTrend(trend) {
    if (!trend.readings$.getValue()) {
      return;
    }
    const readingsArray = trend.readings$
      .getValue()
      .map((reading) => (trend.compareToPast ? reading.value : [moment(reading.timestamp).valueOf(), reading.value]));

    const trendDisplayLabel =
      trend.node.displayLabel.length > 15 ? `${trend.node.displayLabel.substring(0, 15)}...` : trend.node.displayLabel;

    const seriesDisplayLabel = `${trendDisplayLabel} [${trend.channel.displayLabel}]`;

    return {
      id: trend.id,
      name: seriesDisplayLabel,
      fullName: `${trend.node.displayLabel} [${trend.channel.displayLabel}]`,
      channelId: trend.channel.id,
      type: 'line',
      color: trend.color,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
      states: {
        select: {
          color: trend.color,
        },
        hover: {
          enabled: false,
        },
      },
      dashStyle: trend.channel.pointType == 'DRBaselinePoint' ? 'Dash' : 'Solid',
      step:
        trend.channel.pointType == 'DRBaselinePoint' || this.selectedGranularity.code == 'month' ? 'right' : undefined,
      lineWidth: trend.channel.pointType == 'DRBaselinePoint' ? 1 : 2,
      yAxis: trend.channel.uomId,
      data: readingsArray,
      pointStart: this.pointStart,
      pointInterval: this.pointInterval,
    };
  }

  get defaultSeries(): SeriesOptionsType {
    const startView = this.selectedDateRange.start;
    const endView = this.selectedDateRange.end;

    const start = moment(startView).unix() * 1000;
    const end = moment(endView).unix() * 1000;

    let data = [];
    const numIntervals = this.pointInterval;
    let size = 0;
    try {
      let diff = end - start;
      size = Math.round(diff / numIntervals);
      data = new Array(size);
      data.fill(null);
    } catch (e) {}
    return {
      name: ' ',
      data: data,
      type: 'line',
      pointInterval: numIntervals,
      pointStart: this.pointStart,
      showInLegend: false,
    };
  }

  // updateYAxis() {
  //   if (this.chart.yAxis.length === 0) {
  //     this.chart.addAxis({
  //       title: {
  //         text: '',
  //       },
  //     });
  //   } else if (this.chart.yAxis.length >= 2) {
  //     const defaultYAxis = this.chart.axes.find(axis => {
  //       return !axis.isXAxis && axis.userOptions.title === ' ';
  //     });
  //     if (defaultYAxis) {
  //       this.chart.a;
  //     }
  //   }
  //   console.log(this.chart.yAxis);
  // }

  get pointInterval() {
    if (this.selectedGranularity) {
      return parseInt(this.selectedGranularity.duration_in_milliseconds);
    }
  }

  get pointStart() {
    return moment(this.selectedDateRange.start).unix() * 1000;
  }

  setChartLoading() {
    if (this.trends && this.trends.length && this.chart) {
      if (this.trends.some((trend) => trend.loading)) {
        this.chart.showLoading();
      } else {
        this.chart.hideLoading();
      }
    }
  }

  resetChart() {
    if (this.chart) {
      this.chart.update(
        {
          series: [this.defaultSeries],
          yAxis: [
            {
              visible: false,
              title: {
                text: '',
              },
            },
          ],
        },
        true,
        true,
        true,
      );
    }
  }
}
