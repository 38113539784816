import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { ComparisonType } from '../models/comparisonType.model';
import { Node } from '../models/node.model';
import { Trend } from '../models/trend.model';
import { Channel } from '../models/channel.model';
import { DeeplinksService } from './deeplinks.service';
import { ComparisonService } from './comparison.service';
import { NodeSelectionService } from './node-selection.service';

@Injectable()
export class TrendsService {
  isInit = true;
  selectedComparisonType: ComparisonType;
  selectedNodes: Node[] = [];

  readonly maxUniqueUOMs: number = 3;
  readonly selectedUOMs$ = new BehaviorSubject<Set<string>>(new Set());
  readonly trends$ = new BehaviorSubject<Trend[]>([]);

  constructor(
    private deeplinksService: DeeplinksService,
    private comparisonService: ComparisonService,
    private nodeSelectionService: NodeSelectionService,
  ) {
    this.trends$.subscribe(trends => {
      if (!this.isInit) {
        this.deeplinksService.updateTrends(trends || []);
      } else {
        this.isInit = false;
      }
    });

    this.comparisonService.selectedComparisonType$.pipe(debounce(() => timer(800))).subscribe(comparisonType => {
      this.selectedComparisonType = comparisonType;
      if (this.selectedComparisonType.type === 'past') {
        if (this.trends$.getValue() && this.trends$.getValue().length) {
          const firstTrend = this.trends$.getValue()[0];
          this.addTrends([new Trend(firstTrend.node, firstTrend.channel, true)]);
        }
      } else {
        if (this.trends$.getValue() && this.trends$.getValue().length) {
          this.trends$
            .getValue()
            .filter(trend => trend.id.includes('_past'))
            .forEach(pastTrend => this.removeTrend(pastTrend.id));
        }
      }
    });

    this.nodeSelectionService.selectedNodes$.subscribe(selectedNodes => {
      this.handleNodeSelection(selectedNodes);
    });
  }

  handleNodeSelection(nodes: Node[]) {
    const newNodes = nodes.filter(node => this.selectedNodes.indexOf(node) === -1);
    this.selectedNodes = nodes;

    newNodes.forEach(node => {
      node.selectedChannels$.pipe(debounce(() => timer(600))).subscribe(selectedChannels => {
        this.handleChannelSelection(node, selectedChannels);
      });
    });
  }

  handleChannelSelection(node: Node, channels: Channel[]) {
    const trendsOfNode = this.getTrendsOfNode(node.id);
    const trendsToRemove = trendsOfNode.filter(trend => channels.indexOf(trend.channel) === -1);

    trendsToRemove.forEach(trend => {
      this.removeTrend(trend.id);
    });

    channels.forEach(channel => {
      if (
        (channel.registration != undefined &&
          !trendsOfNode.some(trend => trend.id === `${channel.registration.id}_${channel.id}`)) ||
        (channel.registration == undefined && !trendsOfNode.some(trend => trend.id === `${node.id}_${channel.id}`))
      ) {
        this.addTrends([new Trend(node, channel)]);
        if (this.selectedComparisonType.type === 'past') {
          this.addTrends([new Trend(node, channel, true)]);
        }
      }
    });
  }

  getTrendsOfNode(nodeId): Trend[] {
    return this.trends$.getValue().filter(trend => trend.node.id === nodeId);
  }

  getTrendsOfChannel(channelId) {
    return this.trends$.getValue().filter(trend => trend.channel.id === channelId);
  }

  addTrends(newTrends: Trend[]) {
    const currentTrends = this.trends$.getValue();
    const allTrends = currentTrends.concat(newTrends);
    const uoms: Set<string> = new Set();
    allTrends.forEach(trend => {
      if (uoms.size < this.maxUniqueUOMs) {
        uoms.add(trend.channel.uomId);
      }
    });
    this.selectedUOMs$.next(uoms);
    this.trends$.next(allTrends);
  }

  removeTrend(trendId: string) {
    let trends = this.trends$.getValue();
    trends = trends.filter(trend => {
      if (trend.id === trendId) {
        trend.destroy();
      }
      return !(trend.id === trendId);
    });
    const uoms: Set<string> = new Set();
    trends.forEach(trend => {
      uoms.add(trend.channel.uomId);
    });
    this.selectedUOMs$.next(uoms);
    this.trends$.next(trends);
  }

  refreshReadings() {
    this.trends$.getValue().forEach(async trend => {
      trend.getReadings();
    });
  }

  removeTrendsUpTo(qty: number) {
    let trends = this.trends$.getValue();
    if (trends.length <= qty) {
      return;
    }
    trends = trends.filter((trend, index) => {
      if (index >= qty) {
        trend.destroy();
        return false;
      }
      return true;
    });
    const uoms: Set<string> = new Set();
    trends.forEach(trend => {
      uoms.add(trend.channel.uomId);
    });
    this.selectedUOMs$.next(uoms);
    this.trends$.next(trends);
  }
}
