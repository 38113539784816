import { Injectable } from '@angular/core';
import { TrendsApiService } from './trends-api.service';

@Injectable()
export class ChannelsService {
  constructor(private nodeApiService: TrendsApiService) {}

  async getChannels(nodeId: string, nodeType: string) {
    switch (nodeType) {
      case 'Program':
        return await this.nodeApiService.getChannelsForProgram(nodeId);
      case 'Portfolio':
        return await this.nodeApiService.getChannelsForPortfolio(nodeId);
      case 'Registration':
        return await this.nodeApiService.getChannelsForRegistration(nodeId);
      default:
        return await this.nodeApiService.getChannelsForSpace(nodeId);
    }
  }

  async getBaselineChannels(nodeId: string, nodeType: string) {
    if (nodeType === 'Site') {
      return await this.nodeApiService.getRegistrationsBaselineChannelsUnderEntity(nodeType, nodeId);
    }
  }
}
