import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CookieService } from './cookie.service';

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.cookieService.getCookie('mock_mode')) {
      const mockResp = sessionStorage.getItem(request.url);

      if (mockResp) {
        const resp: HttpResponse<any> = new HttpResponse({});
        const response = resp.clone({
          body: JSON.parse(mockResp),
          status: 200,
        });

        return of(response);
      } else {
        return next.handle(request);
      }
    } else {
      return next.handle(request);
    }
  }
}
