import { Injectable } from '@angular/core';
import { DATE_FORMAT_STRING, Daterange } from '../models/daterange.model';
import { Granularity } from '../models/granularity.model';
import { ApiService } from './api.service';
import { GranularityService } from './granularity.service';
import { DateRangeService } from './date-range.service';
import * as moment from 'moment-timezone';
import { NodeType } from '../models/nodeType.model';
import { NodeTypeService } from './node-type.service';
import { UserService } from './user.service';
import { CompareToPastService } from './compare-to-past.service';

@Injectable()
export class ReadingsService {
  selectedNodeType: NodeType;
  selectedGranularity: Granularity;
  selectedDateRange: Daterange;
  pastDateRange: Daterange;
  userTimezone: string;
  MACRO_MAX_READINGS = 5000;
  MACRO_MAX_PAGINATED_READINGS = 52200;
  verbose = false;

  constructor(
    private apiService: ApiService,
    private granularityService: GranularityService,
    private dateRangeService: DateRangeService,
    private nodeTypeService: NodeTypeService,
    private userService: UserService,
    private compareToPastService: CompareToPastService,
  ) {
    this.nodeTypeService.selectedNodeType$.subscribe(selectedNodeType => {
      this.selectedNodeType = selectedNodeType;
    });
    this.granularityService.selectedGranularity$.subscribe(granularity => {
      this.selectedGranularity = granularity;
      this.pastDateRange = this.compareToPastService.pastDateRange;
    });
    this.dateRangeService.selectedDateRange$.subscribe(daterange => {
      this.selectedDateRange = {
        start: daterange.start,
        end: moment(daterange.end)
          .add(1, 'second')
          .format(DATE_FORMAT_STRING),
      };
    });

    this.compareToPastService.selectedValueToCompare$.subscribe(valueToCompare => {
      this.pastDateRange = valueToCompare;
    });
    this.userService.user$.subscribe(user => {
      if (user) {
        this.userTimezone = user.preferences.default_time_zone;
      }
    });
  }

  setVerboseMode(mode: boolean) {
    this.verbose = mode;
  }

  getTotalPages() {
    const start = moment(this.selectedDateRange.start);
    const totalMinutes = moment(this.selectedDateRange.end).diff(start, 'minutes');
    const granInMin = parseInt(this.selectedGranularity.duration_in_milliseconds) / 60000;
    const points = totalMinutes / granInMin;
    if (points > this.MACRO_MAX_PAGINATED_READINGS) {
      return -1;
    }
    const totalPages = Math.ceil(points / this.MACRO_MAX_READINGS);
    return totalPages;
  }

  getPaginatedReadings(nodeId, nodeType, params, totalPages) {
    const paginatedRequests = [];
    for (let page = 1; page <= totalPages; page++) {
      paginatedRequests.push(this.getReadingsByDrType(nodeId, nodeType, { ...params, page }));
    }
    return Promise.all(paginatedRequests);
  }

  stichedPaginatedReadings(responeses, nodeId, channelId) {
    const reads = responeses.map(res => res[nodeId][channelId].readings);
    let stichedReadings = [];
    for (let read of reads) {
      stichedReadings = [...stichedReadings, ...read];
    }
    const stichedResponse = responeses[0];
    stichedResponse[nodeId][channelId].readings = [...stichedReadings];
    return stichedResponse;
  }

  async getReadings(
    nodeId: string,
    channelId: string,
    drType: string,
    pointType: string,
    compareToPast: boolean,
    forcedGranularity: string,
    notAggregated: boolean,
  ) {
    let verboseParam = {};
    if (this.verbose) {
      verboseParam = { verbose: this.verbose };
    }
    if (this.selectedGranularity && this.userTimezone) {
      const params = {
        channel_id: channelId,
        granularity: notAggregated ? forcedGranularity : this.selectedGranularity.code,
        start_dttm: compareToPast ? this.pastDateRange.start : this.selectedDateRange.start,
        end_dttm: compareToPast ? this.pastDateRange.end : this.selectedDateRange.end,
        timezone: this.userTimezone,
        point_type: pointType,
        ...verboseParam,
      };

      const numberOfPages = this.getTotalPages();

      if (numberOfPages > 1) {
        console.log('paginated');
        const paginatedRequests = await this.getPaginatedReadings(nodeId, drType, params, numberOfPages);
        return this.stichedPaginatedReadings([...paginatedRequests], nodeId, channelId);
      }
      if (numberOfPages == 1) {
        return await this.getReadingsByDrType(nodeId, drType, params);
      }
      return [];
    }
  }

  async getReadingsByDrType(nodeId, drType, params) {
    switch (drType) {
      case 'Program':
        return await this.apiService.get(`programs/${nodeId}/readings`, params);
      case 'Portfolio':
        return await this.apiService.get(`portfolios/${nodeId}/readings`, params);
      case 'Registration':
        return await this.apiService.get(`registrations/${nodeId}/readings`, params);
      default:
        return await this.apiService.get(`spaces/${nodeId}/readings`, params);
    }
  }
}
