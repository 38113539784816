import { Component, OnInit } from '@angular/core';
import { ComparisonService } from '../../services/comparison.service';
import { ComparisonTypes } from '../../models/comparisonType.model';
import { MixPanelService } from '../../services/mixpanel.service';

@Component({
  selector: 'app-chart-tabs',
  templateUrl: './chart-tabs.component.html',
  styleUrls: ['./chart-tabs.component.scss'],
})
export class ChartTabsComponent implements OnInit {
  tabs;
  selectedComparisonType;

  constructor(private comparisonService: ComparisonService, private mixpanelService: MixPanelService) {
    this.tabs = ComparisonTypes;
    this.comparisonService.selectedComparisonType$.subscribe(comparisonType => {
      this.selectedComparisonType = comparisonType;
    });
  }

  ngOnInit() {}

  changeTab(selectedTab): void {
    if (selectedTab.type === 'trends') {
      this.mixpanelService.viewCompareTrends();
    }
    if (selectedTab.type === 'past') {
      this.mixpanelService.compareToPast();
    }
    if (selectedTab.type === 'raw') {
      this.mixpanelService.viewRawData();
    }
    this.comparisonService.setComparisonType(selectedTab);
  }
}
