import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Context, ContextSelectorService, Space } from 'ngx-global-nav';
import { NodeSelectionService } from '../services/node-selection.service';
import { SelectionModel } from '@angular/cdk/collections';
import { TreeNode, NodeDefinition } from 'enel-tree';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { NodeService } from '../services/node.service';
import { NodeType } from '../models/nodeType.model';
import { ComparisonService } from '../services/comparison.service';
import { NodeTypeService } from '../services/node-type.service';
import { Program } from '../models/program.model';
import { ORG_ICON_DEFINITION, PROGRAM_ICON_DEFINITION } from '../models/nodeType.model';
import { MixPanelService } from '../services/mixpanel.service';
import { TranslateService } from '@ngx-translate/core';

const TYPES_TO_FETCH_CHILDREN = ['Program'];

@Component({
  selector: 'app-node-selector',
  templateUrl: './node-selector.component.html',
  styleUrls: ['./node-selector.component.scss'],
})
export class NodeSelectorComponent implements OnInit {
  isInit = true;
  loading = true;
  multiSelectMode = true;
  maxSelections = 10;
  nodes: any[];
  disabledNodeDefinitions: NodeDefinition;
  placeholderNodeAttributes = 'nodePlaceholder';
  placeholderNodeValues: string[] = ['loading'];
  placeholderNodeDefinitions: NodeDefinition = { [this.placeholderNodeAttributes]: this.placeholderNodeValues };
  labelIdentifier: string = 'displayLabel';
  typeIdentifier: string = 'type';
  iconIdentifier: string = 'type';
  iconDefinitions: any = { ...ORG_ICON_DEFINITION, ...PROGRAM_ICON_DEFINITION };
  defaultNodes: string[] = [];
  selection: SelectionModel<TreeNode<any>> | undefined;
  isOnDataRaw: boolean = false;
  searchText = '';
  expandedKeys = new Set<TreeNode<any>>();
  selectedKeys = new Set<TreeNode<any>>();
  orgSelectedNodes: string[];
  programSelectedNodes: string[];

  deselectedId = '';

  selectedNodeType: NodeType;
  orgSubscription;
  contexts: Context[];

  constructor(
    private contextSelectorService: ContextSelectorService,
    private nodeSelectionService: NodeSelectionService,
    private nodeService: NodeService,
    private nodeTypeService: NodeTypeService,
    private comparisonService: ComparisonService,
    private mixpanelService: MixPanelService,
    private translateService: TranslateService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {
    iconRegistry.addSvgIconSetInNamespace('enelx', sanitizer.bypassSecurityTrustResourceUrl('/assets/enelx-icons.svg'));
    iconRegistry.addSvgIcon('search', sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/search-icon.svg'));
    Object.keys(this.iconDefinitions).forEach((iconName: string) => {
      iconRegistry.addSvgIcon(
        this.iconDefinitions[iconName],
        sanitizer.bypassSecurityTrustResourceUrl(`/assets/svg/${this.iconDefinitions[iconName]}.svg`),
      );
    });

    this.nodeService.spaces$.subscribe((spaces) => {
      if (spaces && spaces.length > 0) {
        this.setTreeNodes(spaces, 'organization');
      }
    });

    this.nodeService.programs$.subscribe((programs) => {
      if (programs && programs.length > 0) {
        this.setTreeNodes(programs, 'program');
      }
    });

    this.nodeService.sites$.subscribe((sites) => {
      if (sites && sites.length > 0) {
        this.setTreeNodes(sites, 'organization');
      }
    });

    this.nodeSelectionService.selectedNodes$.subscribe((nodes) => {
      this.defaultNodes = nodes.map((node) => node.id);
    });

    this.orgSubscription = this.contextSelectorService.currentContext$.subscribe(async (contexts: Context[]) => {
      if (contexts && contexts.length && contexts[0].id) {
        this.contexts = contexts;
        if (this.selectedNodeType && this.selectedNodeType?.type === 'organization') {
          this.nodeSelectionService.deselectAllNodes();
          this.loading = true;
          this.nodeService.getSpaces(contexts);
        }
      }
    });

    this.nodeTypeService.selectedNodeType$.subscribe(async (nodeType) => {
      if (nodeType) {
        this.selectedNodeType = nodeType;
        this.nodes = [];
        this.iconIdentifier = nodeType.typeKey;
        this.disabledNodeDefinitions = nodeType.disabledNodeTypes;
        this.searchText = '';

        switch (nodeType.type) {
          case 'organization':
            this.mixpanelService.selectNodeType(nodeType.type);
            this.programSelectedNodes = [...this.defaultNodes];
            if (this.orgSelectedNodes && this.orgSelectedNodes.length > 0) {
              this.defaultNodes = [...this.orgSelectedNodes];
            }

            if (this.contexts) {
              this.loading = true;
              this.nodeService.getSpaces(this.contexts);
            }
            break;

          case 'program':
            this.mixpanelService.selectNodeType(nodeType.type);
            this.orgSelectedNodes = [...this.defaultNodes];
            if (this.programSelectedNodes && this.programSelectedNodes.length > 0) {
              this.defaultNodes = [...this.programSelectedNodes];
            }

            this.loading = true;
            this.nodeService.getPrograms();
            break;
        }
      }
    });

    this.comparisonService.selectedComparisonType$.subscribe((comparisonType) => {
      switch (comparisonType.type) {
        case 'trends':
          this.multiSelectMode = true;
          this.maxSelections = 10;
          this.isOnDataRaw = false;
          break;
        case 'raw':
          this.multiSelectMode = true;
          this.maxSelections = 5;
          this.isOnDataRaw = true;
          break;
        case 'past':
          this.multiSelectMode = false;
          this.maxSelections = 1;
          this.isOnDataRaw = false;
          break;
      }
    });
  }

  setTreeNodes(nodes, nodeType) {
    if (nodeType !== this.selectedNodeType.type) {
      return;
    }

    // If program seed nodes with placeholder children
    if (nodeType === 'program') {
      const placeholder: TreeNode<any> = {
        children: [],
        id: 'placeholder',
        displayLabel: this.translateService.instant('trends.loading'),
        nodePlaceholder: 'loading',
      };
      nodes.map((node) => {
        if (node.drType === 'Program') {
          node.children = [placeholder];
        }
      });
      this.loadedProgramIds = [];
    }
    this.nodes = nodes;
    this.loading = false;
    this.nodeTypeService.loadingNodes$.next(false);
    this.setDefaultNodes();
  }

  setDefaultNodes() {
    if (!this.nodes || this.loading) {
      return;
    }
    if (this.isInit && this.nodeService.defaultNodeIds) {
      // Default IDs from the deeplinker
      this.defaultNodes = this.nodeService.defaultNodeIds;
    }

    //Select the default nodes
    const selectedNodes = this.findNodesOnTreeByIds([...this.defaultNodes], this.nodes);
    if (selectedNodes.length > 0) {
      this.selectedKeys = new Set(selectedNodes);
    } else {
      this.selectedKeys = new Set([this.nodes[0]]);
    }
    this.isInit = false;
  }

  findNodesOnTreeByIds(nodeIds: any[], tree: any[]) {
    const foundNodes = [];
    const mapIds = new Map<string, any>();
    nodeIds.forEach((id) => {
      mapIds.set(id, false);
    });
    this.searchTree(mapIds, tree, foundNodes);
    return foundNodes;
  }

  searchTree(mapIds, tree, foundNodes) {
    for (let node of tree) {
      let allFound = true;
      mapIds.forEach((value) => {
        allFound = value ? allFound : false;
      });
      if (allFound) {
        break;
      }
      if (mapIds.get(node.id) !== undefined) {
        foundNodes.push(node);
        mapIds.set(node.id, true);
      }
      if (node.children) {
        this.searchTree(mapIds, node.children, foundNodes);
      }
    }
  }

  handleSelection(selection: any[]) {
    this.nodeSelectionService.selectTreeNodes(selection);
  }

  handleDeselection(selection: any[]) {
    const selectedIds = selection.map((selected) => selected.id);
    const toRemove = this.defaultNodes.filter((node) => !selectedIds.includes(node));
    this.nodeSelectionService.deselectTreeNodes(toRemove);
  }

  async handleExpansion(nodes: any[]) {
    if (nodes.length > 0) {
      const node = nodes[nodes.length - 1];
      if (node.children && node.children[0].id == 'placeholder') {
        const newChildren = await this.fetchNodeChildren(node);
        node.children = newChildren;
        this.updateTree(node);
      }
    }
  }

  loadedProgramIds = [];

  updateTree(node) {
    this.nodes = this.nodes.map((treeNode) => {
      if (treeNode.id == node.id) {
        treeNode.children = node.children;
      }
      return treeNode;
    });
  }

  async fetchNodeChildren(node) {
    // Early return if children already loaded
    if (node.children && node.children[0].id != 'placeholder') {
      return node.children;
    }
    // Fetch children if required and not already loaded
    if (this.isLoadedOnDemand(node.drType) && !this.loadedProgramIds.includes(node.id)) {
      return this.loadAndSetChildren(node);
    }
    // Return empty array if no children are present
    return [];
  }

  async loadAndSetChildren(node) {
    const children = await this.nodeService.getChildrenHierarchy(node.id, node.drType);
    // Update the found node
    const foundNode = (this.nodes as Program[]).find((storedNode) => storedNode.id === node.id);
    if (foundNode) {
      foundNode.children = children;
    }
    // Update the current node
    node.children = children;
    // Reset search and update loaded IDs
    this.currentSearch = '';
    this.loadedProgramIds.push(node.id);
    return children;
  }

  currentSearch = '';

  isTypeOrg() {
    return this.selectedNodeType.type == 'organization';
  }

  isLoadedOnDemand(type) {
    return TYPES_TO_FETCH_CHILDREN.includes(type);
  }

  async ngOnInit() {
    this.nodeSelectionService.deselect$.subscribe((nodeId) => {
      this.deselectedId = nodeId;
      this.cdr.detectChanges();
      this.deselectedId = '';
    });
  }

  sendSearchTextToMixpanel() {
    this.mixpanelService.search(this.searchText);
  }
}
