import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeeplinksService } from './deeplinks.service';
import { Daterange, DATE_FORMAT_STRING } from '../models/daterange.model';
import moment from 'moment-timezone';
import { UserService } from './user.service';

@Injectable()
export class DateRangeService {
  userTimezone: string;
  defaultDateRange: Daterange = {
    start: moment().startOf('day').format(DATE_FORMAT_STRING),
    end: moment().endOf('day').format(DATE_FORMAT_STRING),
  };

  readonly selectedDateRange$ = new BehaviorSubject<Daterange>(null);
  pastDateRange: Daterange;

  constructor(private deeplinkService: DeeplinksService, private userService: UserService) {
    this.deeplinkService.initialDeeplinks$.subscribe((deeplinks) => {
      if (deeplinks.startDate && deeplinks.endDate) {
        const rangeInSeconds = moment(deeplinks.endDate).diff(moment(deeplinks.startDate), 'seconds');
        if (rangeInSeconds === 0) {
          deeplinks.endDate = moment(deeplinks.endDate).add(1, 'days').format(DATE_FORMAT_STRING);
        }
        this.defaultDateRange = { start: deeplinks.startDate, end: deeplinks.endDate };
      }
    });

    this.userService.user$.subscribe((user) => {
      if (user) {
        this.userTimezone = user.preferences.default_time_zone;
        this.setDateRange(this.defaultDateRange);
      }
    });
  }

  setDateRange(dateRange: Daterange): void {
    dateRange.start = moment(dateRange.start).format(DATE_FORMAT_STRING);
    dateRange.end = moment(dateRange.end).format(DATE_FORMAT_STRING);
    this.selectedDateRange$.next(dateRange);
    this.deeplinkService.updateDateRange(dateRange);
  }
}
