import { Trend } from './trend.model';
import { InjectorService } from '../services/injector.service';
import { MathService } from '../services/math.service';
import * as moment from 'moment-timezone';

interface SummaryCalculation {
  key: string;
  mobile_key: string;
  func: string;
  value: string;
}

const COLUMN_HEADER_MAP = {
  AVG: [
    {
      key: 'trends.summary.min',
      mobile_key: 'trends.summary.mobile.min',
      func: 'minimum',
      value: null,
    },
    {
      key: 'trends.summary.max',
      mobile_key: 'trends.summary.mobile.max',
      func: 'maximum',
      value: null,
    },
    {
      key: 'trends.summary.average',
      mobile_key: 'trends.summary.mobile.average',
      func: 'average',
      value: null,
    },
  ],
  SUM: [
    {
      key: 'trends.summary.min',
      mobile_key: 'trends.summary.mobile.min',
      func: 'minimum',
      value: null,
    },
    {
      key: 'trends.summary.max',
      mobile_key: 'trends.summary.mobile.max',
      func: 'maximum',
      value: null,
    },
    {
      key: 'trends.summary.average',
      mobile_key: 'trends.summary.mobile.average',
      func: 'average',
      value: null,
    },
    {
      key: 'trends.summary.sum',
      mobile_key: 'trends.summary.mobile.sum',
      func: 'sum',
      value: null,
    },
  ],
};

export class Summary {
  channelId: string;
  channelDisplayLabel: string;
  aggregationType: string;
  columns: SummaryCalculation[];
  trends: Trend[];
  uomId: string;
  uomDisplayLabel: string;
  hasReadings: boolean = null;

  private mathService: MathService;

  constructor(channel: any) {
    this.channelId = channel.id;
    this.channelDisplayLabel = channel.displayLabel;
    this.aggregationType = channel.aggregationType;
    this.uomId = channel.uomId;
    this.uomDisplayLabel = channel.uomDisplayLabel;
    this.columns = COLUMN_HEADER_MAP[this.aggregationType] || COLUMN_HEADER_MAP.AVG;
    this.trends = [];
    this.mathService = InjectorService.injector.get(MathService);
  }

  addTrend(trend: Trend) {
    // LISTEN TO CHANGES IN READINGS TO UPDATE SUMMARY DATA
    trend.readings$.subscribe(readings => {
      if (trend.readings && trend.readings.length > 0) {
        this.hasReadings = true;
      }
      this.calculateSummaryForTrend(trend);
    });
    this.trends.push(trend);
  }

  calculateSummaryForTrend(trend: Trend) {
    let sanitizedReadings;
    if (trend.readings) {
      if (trend.readings.length > 0) {
        sanitizedReadings = trend.readings.map(reading => reading.value).filter(reading => reading !== null);
      }
    }

    const summary = this.columns.map(calculation => {
      let data = {
        value: '-',
        timestamp: '',
        parsedValue: null,
      };
      if (sanitizedReadings) {
        let value = this.mathService[calculation.func](sanitizedReadings);
        if (calculation.func === 'minimum' || calculation.func === 'maximum') {
          data = { ...trend.readings.find(reading => reading.value === value) };
          data.timestamp =
            moment(data.timestamp).format('L') +
            ' ' +
            moment(data.timestamp).format('LTS') +
            ' ' +
            moment(data.timestamp).format('zz');
        } else {
          data.value = value;
        }
        data.parsedValue = value;
        data.value = this.mathService.formatNumber(value);
      }
      return data;
    });
    trend.setSummaryData(summary);
  }
}
