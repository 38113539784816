<!-- <h1 mat-dialog-title>Trend Reading Metadata</h1> -->
<div mat-dialog-content>
    <div *ngFor="let item of data | keyvalue" class="object-item">
        <mat-item>
            <span class="metadata-key">{{ 'trends.' + item.key | translate }}: </span>
            <span class="metadata-value">{{
                isNumber(item.value) ? item.value : ('trends.' + item.value | translate)
            }}</span>
        </mat-item>
    </div>
</div>
