import { Injectable } from '@angular/core';
import { TrendsService } from './trends.service';
import { BehaviorSubject } from 'rxjs';
import { Summary } from '../models/summary.model';

@Injectable()
export class SummaryService {
  readonly summaries$ = new BehaviorSubject<Summary[]>([]);

  constructor(private trendsService: TrendsService) {
    this.trendsService.trends$.subscribe(trends => {
      const summaries: Summary[] = [];
      trends.forEach(trend => {
        // CREATE SUMMARY MODEL
        const existingSummary = summaries.find(summary => {
          return summary.channelId === trend.channel.id;
        });
        if (existingSummary) {
          existingSummary.addTrend(trend);
        } else {
          const newSummary = new Summary(trend.channel);
          newSummary.addTrend(trend);
          summaries.push(newSummary);
        }
      });
      this.summaries$.next(summaries);
    });
  }
}
