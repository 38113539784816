<div class="tab-container">
    <h6 class="node-selection-limit">
        <div *ngIf="nodes && nodes.length > 0">
            <ng-container *ngIf="!isOnDataRaw; else DataRaw">
                {{ 'trends.sidebar.tab.' + selectedNodeType.type + '.select' | translate }}
            </ng-container>
            <ng-template #DataRaw>
                {{ 'trends.sidebar.tab.raw.select' | translate }}
            </ng-template>
        </div>
        <div *ngIf="selectedNodeType && (!nodes || nodes.length <= 0)">
            {{ 'trends.sidebar.tab.' + selectedNodeType.type + '.none_found' | translate }}
        </div>
    </h6>

    <form class="context-search-row">
        <div class="x-icon-input tree-search">
            <mat-icon svgIcon="search" class="input-icon" color="primary"></mat-icon>
            <input
                autofocus
                data-automation-name="search-text-field"
                [(ngModel)]="searchText"
                type="text"
                name="searchText"
                placeholder="{{ 'trends.sidebar.tab.search' | translate }}"
                (focusout)="sendSearchTextToMixpanel()"
            />
        </div>
    </form>

    <!-- org list -->
    <div class="context-list" *ngIf="!loading && !isInit">
        <ng-container *ngIf="isTypeOrg()">
            <enel-tree-display
                [treeData]="nodes"
                [searchString]="searchText"
                [labelIdentifier]="labelIdentifier"
                [typeIdentifier]="typeIdentifier"
                [showControls]="true"
                [showSearch]="false"
                [deselect]="deselectedId"
                [selectedNodes]="selectedKeys"
                [multiSelect]="multiSelectMode"
                [maxSelections]="maxSelections"
                [checkbox]="true"
                (selection$)="handleSelection($event)"
                (deselection$)="handleDeselection($event)"
                [hideIrrelevant]="true"
                [iconDefinitions]="iconDefinitions"
                [iconIdentifier]="iconIdentifier"
                data-automation="locationTreeSelector"
            >
            </enel-tree-display>
        </ng-container>
        <ng-container *ngIf="!isTypeOrg()">
            <enel-tree-display
                [treeData]="nodes"
                [searchString]="searchText"
                [labelIdentifier]="labelIdentifier"
                [typeIdentifier]="typeIdentifier"
                [deselect]="deselectedId"
                [selectedNodes]="selectedKeys"
                [multiSelect]="multiSelectMode"
                [maxSelections]="maxSelections"
                [checkbox]="true"
                [showControls]="true"
                [showSearch]="false"
                (expansion$)="handleExpansion($event)"
                (selection$)="handleSelection($event)"
                (deselection$)="handleDeselection($event)"
                [hideIrrelevant]="true"
                [iconDefinitions]="iconDefinitions"
                [iconIdentifier]="iconIdentifier"
                [placeholderDefinitions]="placeholderNodeDefinitions"
                data-automation="locationTreeSelector"
            >
            </enel-tree-display>
        </ng-container>
    </div>

    <div *ngIf="loading" class="spaces-loading-spinner">
        <mat-spinner color="primary" diameter="64"></mat-spinner>
    </div>
</div>
