import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, timer, combineLatest } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { Daterange, DATE_FORMAT_STRING } from '../models/daterange.model';
import { Trend } from '../models/trend.model';
import { DateRangeService } from './date-range.service';
import { ComparisonService } from '../services/comparison.service';
import { GranularityService } from '../services/granularity.service';
import { Granularity } from '../models/granularity.model';

@Injectable()
export class CompareToPastService {
  selectedDateRange: Daterange;
  trends: Trend[] = [];
  pastDateRange: Daterange;
  granularity: Granularity;
  isInit = true;

  readonly selectedValueToCompare$ = new BehaviorSubject<Daterange>(null);
  readonly selectedCompareToPast$ = new BehaviorSubject<string>(null);
  constructor(
    private dateRangeService: DateRangeService,
    private comparisonService: ComparisonService,
    private granularityService: GranularityService
  ) {
    this.dateRangeService.selectedDateRange$.subscribe((dateRange) => {
      if(dateRange){
        this.selectedDateRange = dateRange;
        if(this.isInit) {
          this.pastDateRange = this.getDefaultPastDateRange(this.selectedDateRange);
          this.isInit = false;
        }
      }
    });

    this.comparisonService.selectedComparisonType$.subscribe((comparisonType) => {
      if(!this.isInit){
        this.calculatePastDateRange();
      }
    });
    
    this.granularityService.selectedGranularity$.subscribe((granularity) => {
      if(granularity){
        this.granularity = granularity;
        console.log(granularity);
      }
    });
  }


  calculatePastDateRange() {
    let selectedPastDateRange = this.comparisonService.selectedPastDateRange$.value;
    switch (selectedPastDateRange) {
      case 'week':
        this.pastDateRange.start = moment(this.selectedDateRange.start)
          .subtract(1, 'year')
          .format(DATE_FORMAT_STRING);
        this.pastDateRange.end = moment(this.selectedDateRange.start)
          .subtract(1, 'year')
          .add(1, 'w')
          .format(DATE_FORMAT_STRING);
        break;
      case 'month':
        this.pastDateRange.start = moment(this.selectedDateRange.start)
          .subtract(1, 'year')
          .format(DATE_FORMAT_STRING);
        this.pastDateRange.end = moment(this.selectedDateRange.start)
          .subtract(1, 'year')
          .add(1, 'M')
          .format(DATE_FORMAT_STRING);
        break;
      case 'year':
        this.pastDateRange.start = moment(this.selectedDateRange.start)
          .subtract(1, 'year')
          .format(DATE_FORMAT_STRING);
        if(this.granularity?.granularity_name === 'DAY' || this.granularity?.granularity_name === 'MONTH'){
          this.pastDateRange.end = moment(this.selectedDateRange.end)
            .subtract(1, 'year')
            .add(1, 'day')
            .format(DATE_FORMAT_STRING);
        } else {
          this.pastDateRange.end = moment(this.selectedDateRange.end)
          .subtract(1, 'year')
          .format(DATE_FORMAT_STRING);
        }
        break;

      case 'previous':
        const range = moment(this.selectedDateRange.end)
          .add(1, 'day')
          .diff(moment(this.selectedDateRange.start), 'days');

        this.pastDateRange.start = moment(this.selectedDateRange.start)
          .subtract(range || 1, 'day')
          .format(DATE_FORMAT_STRING);
        this.pastDateRange.end = moment(this.selectedDateRange.start).format(DATE_FORMAT_STRING);
    }
    this.selectedValueToCompare$.next(this.pastDateRange);
    this.selectedCompareToPast$.next(selectedPastDateRange);
    return this.pastDateRange;
  }

  getDefaultPastDateRange(dateRange) {
    const range = moment(dateRange.end)
      .add(1, 'day')
      .diff(moment(dateRange.start), 'days');
    return {
      start: moment(dateRange.start)
        .subtract(range || 1, 'day')
        .format(DATE_FORMAT_STRING),
      end: moment(dateRange.start).format(DATE_FORMAT_STRING),
    };
  }
}
