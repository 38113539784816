import { BehaviorSubject } from 'rxjs';
import { TreeNode } from 'enel-tree';
import { Channel } from './channel.model';
import { ChannelCategory } from './channel-category.model';
import { InjectorService } from '../services/injector.service';
import { ChannelsService } from '../services/channels.service';
import { NodeTypeService } from '../services/node-type.service';

const DEFAULT_CHANNEL_UOM = 'KW';
const POINT_TYPES_WHITELIST = ['MeasuredPoint', 'DRBaselinePoint', 'TelemetryPoint', 'ExternalPoint'];
export class Node {
  id: string;
  displayLabel: string;
  type: string;
  timezone: string;
  readonly loadingChannels$ = new BehaviorSubject<boolean>(true);
  readonly channelCategories$ = new BehaviorSubject<ChannelCategory[]>([]);
  readonly baselineChannels$ = new BehaviorSubject<Channel[]>([]);
  readonly selectedChannels$ = new BehaviorSubject<Channel[]>([]);

  private channelsService: ChannelsService;
  private nodetypeService: NodeTypeService;
  treeNode: TreeNode<any>;
  subscriptions = [];

  constructor(id: string, displayLabel: string, nodeType: string, timezone: string, treeNode: TreeNode<any>) {
    this.channelsService = InjectorService.injector.get(ChannelsService);
    this.nodetypeService = InjectorService.injector.get(NodeTypeService);
    this.id = id;
    this.displayLabel = displayLabel;
    this.type = nodeType;
    this.timezone = timezone;
    this.treeNode = treeNode;
    this.loadChannels();
  }

  async loadChannels() {
    this.loadingChannels$.next(true);
    const channelCategories = await this.channelsService.getChannels(this.id, this.type);
    const baselineChannels = await this.channelsService.getBaselineChannels(this.id, this.type);
    this.setBaselineChannels(baselineChannels);
    this.setChannelCategories(channelCategories);
    this.loadingChannels$.next(false);
  }

  selectChannels(channels: Channel[]): void {
    channels.forEach(channel => {
      channel.selected = true;
    });
    const currentChannels = this.selectedChannels$.getValue();
    this.selectedChannels$.next(currentChannels.concat(channels));
  }

  deselectChannels(channels: Channel[]): void {
    let newChannels = this.selectedChannels$.getValue();
    channels.forEach(removedChannel => {
      removedChannel.selected = false;
      newChannels = newChannels.filter(channel => {
        return JSON.stringify(channel) !== JSON.stringify(removedChannel);
      });
    });
    this.selectedChannels$.next(newChannels);
  }

  setBaselineChannels(baselineChannels: any[]) {
    if (!baselineChannels || baselineChannels.length <= 0) {
      return;
    }
    const channels = baselineChannels.map(baselineChannel => {
      const newChannel = new Channel(
        baselineChannel.channel.id,
        baselineChannel.channel.display_label,
        baselineChannel.channel.point_type,
        baselineChannel.channel.uom,
        baselineChannel.channel.hierarchy_aggregation_type_id,
        baselineChannel.channel.granularity,
        baselineChannel.channel.base_granularity,
        baselineChannel.channel.reporting_interval_ms,
        baselineChannel.channel.description,
      );
      return { ...newChannel, registration: baselineChannel.registration };
    });
    this.baselineChannels$.next(channels);
    this.selectDefaultBaselineChannel();
  }

  setChannelCategories(channelCategories: any[]): void {
    const categories = channelCategories.map((channelCategory: any) => {
      const channels = channelCategory.channels.filter(channel => POINT_TYPES_WHITELIST.includes(channel.point_type));
      return new ChannelCategory(channelCategory.id, channelCategory.display_label, channels);
    });
    this.channelCategories$.next(categories);
    this.selectDefaultChannel();
  }

  selectDefaultBaselineChannel(){
    const defaultBaselineChannel = this.getBaselineById('4021');
    if(defaultBaselineChannel){
      this.selectChannels([defaultBaselineChannel])
    }
  }

  selectDefaultChannel() {
    const nodeType = this.nodetypeService.selectedNodeType$.getValue();
    const defaultChannel = this.getChannelById('4020');
    if (defaultChannel) {
      this.selectChannels([defaultChannel]);
    } else {
      const firstChannel = this.getFirstChannel();
      if (firstChannel) {
        this.selectChannels([firstChannel]);
      }
    }
  }

  getChannelByUOM(channelUOM: string) {
    const channelCategories = this.channelCategories$.value;
    let channel = null;
    channelCategories.forEach(category => {
      if (channel) {
        return;
      }
      channel =
        category.channels.find((channel: Channel) => {
          return channel.uomId === channelUOM;
        }) || channel;
    });
    return channel;
  }

  getChannelById(channelId: string) {
    const channelCategories = this.channelCategories$.value;
    let channel = null;
    channelCategories.forEach(category => {
      channel =
        category.channels.find(channel => {
          return channel.id === channelId;
        }) || channel;
    });
    return channel;
  }

  getBaselineById(baselineId: string){
    const baseline = this.baselineChannels$.value;
    let baselineChannel = null;
    baseline.forEach(channel => {
      if(channel.id === baselineId){
        baselineChannel = channel
        return channel
      }
    })
    return baselineChannel;
  }

  getFirstChannel() {
    const channelCategories = this.channelCategories$.value;
    for (const category of channelCategories) {
      if (category.channels && category.channels.length > 0) {
        return category.channels[0];
      }
    }
  }

  destroy(): void {
    this.deselectChannels(this.selectedChannels$.getValue());
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.selectedChannels$.complete();
    this.channelCategories$.complete();
    this.loadingChannels$.complete();
  }
}
