<global-nav [disabledNodeValues]="disabledContextValues" [multiSelect]="true" [maxSelections]="5" [navUrl]="navUrl"></global-nav>
<div class="main-container" class="{{ theme }}">
    <mat-sidenav-container class="x-sidebar">
        <mat-sidenav
            #sidenav
            mode="side"
            fixedInViewport="true"
            fixedTopGap="60"
            [mode]="isLargeScreen() ? 'side' : 'over'"
            [(opened)]="sidenavOpen"
            class="sidebar-container"
        >
            <div class="sidebar-header">
                <div class="left-column">
                    <h1
                        class="sidebar-title ellipsis"
                        data-automation="listTitle"
                        title="{{ 'trends.sidebar.title' | translate }}"
                    >
                        {{ 'trends.sidebar.title' | translate }}
                    </h1>
                </div>
                <div class="right-column">
                    <button
                        mat-icon-button
                        color="accent"
                        aria-label="Menu"
                        class="sidebar-toggle-button"
                        data-automation="toggle-sidenav"
                        (click)="sidenav.toggle()"
                    >
                        <mat-icon (click)="sendDrawerActionToMixpanel()" class="sidebar-toggle-icon">double_arrow</mat-icon>
                    </button>
                </div>
            </div>
            <div class="sidebar-component-container">
                <app-sidebar [sidenav]="sidenav"></app-sidebar>
            </div>
        </mat-sidenav>

        <mat-sidenav-content class="content-container">
            <div class="content-section">
                <div class="header">
                    <div class="sidebar-toggle" (click)="sidenav.toggle()">
                        <div class="page-title-container">
                            <div class="icon-container">
                                <mat-icon [color]="'primary'">show_chart</mat-icon>
                            </div>
                            <span class="page-title" translate="trends.content.title"></span>
                        </div>
                    </div>
                    <app-historical-data-button></app-historical-data-button>
                    <app-export-button></app-export-button>
                </div>

                <div class="content">
                    <app-chart-tabs></app-chart-tabs>
                    <app-chart-controls></app-chart-controls>
                    <app-chart-error></app-chart-error>
                    <div [hidden]="selectedComparisonType.type === 'raw'">
                        <app-chart></app-chart>
                    </div>
                    <ng-container *ngIf="selectedComparisonType.type === 'raw'">
                        <app-raw-data-table></app-raw-data-table>
                    </ng-container>
                </div>
            </div>

            <ngx-global-contact-footer></ngx-global-contact-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
