import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Granularity } from '../models/granularity.model';
import { NodeType } from '../models/nodeType.model';
import { Daterange, DATE_FORMAT_STRING } from '../models/daterange.model';
import { Trend } from '../models/trend.model';
import { DateRangeService } from './date-range.service';
import { DeeplinksService } from './deeplinks.service';
import { TrendsApiService } from './trends-api.service';
import { NodeTypeService } from './node-type.service';
import { TrendsService } from './trends.service';
import * as moment from 'moment-timezone';

@Injectable()
export class GranularityService {
  isInit = true;
  selectedNodeType: NodeType;
  selectedDateRange: Daterange;
  maxReportingInterval: number = 3600000;
  allgranularities: any;
  private deeplinkedGranularity;

  previousGranularityID: string;

  readonly minReportingInterval$ = new BehaviorSubject<number>(null);
  readonly selectedGranularity$ = new BehaviorSubject<Granularity>(null);
  readonly availableGranularities$ = new BehaviorSubject<any[]>([]);

  constructor(
    private nodeApiService: TrendsApiService,
    private nodeTypeService: NodeTypeService,
    private daterangeService: DateRangeService,
    private deeplinkService: DeeplinksService,
    private trendsService: TrendsService,
  ) {
    this.nodeTypeService.selectedNodeType$.subscribe((selectedNodeType) => {
      this.selectedNodeType = selectedNodeType;
    });

    this.daterangeService.selectedDateRange$.subscribe((selectedDateRange) => {
      if (selectedDateRange) {
        if (this.shouldRefreshGranularities(selectedDateRange)) {
          this.selectedDateRange = selectedDateRange;
          this.getGranularities(selectedDateRange.start, selectedDateRange.end, 'MeasuredPoint');
        }
      }
    });
    this.deeplinkService.initialDeeplinks$.subscribe((deeplinks) => {
      if (deeplinks.granularity) {
        this.deeplinkedGranularity = deeplinks.granularity;
      }
    });
    this.trendsService.trends$.subscribe((trends) => {
      if (trends && trends.length > 0) {
        this.calculateMinReportingInterval(trends);
      } else {
        this.minReportingInterval$.next(null);
      }
    });
  }

  async getGranularities(startDate: string, endDate: string, pointType: string) {
    const granularities = await this.nodeApiService.getGranularities(startDate, endDate, pointType);
    if (granularities) {
      this.availableGranularities$.next(granularities);
      this.validateAndSelectGranularity(this.getDefaultGranularity(granularities));
    }
  }

  async getAllGranularities() {
    const start = moment().subtract(1, 'days').startOf('day').format(DATE_FORMAT_STRING);
    const end = moment().startOf('day').format(DATE_FORMAT_STRING);
    this.allgranularities = await this.nodeApiService.getGranularities(start, end, 'MeasuredPoint', true);
  }

  shouldRefreshGranularities(newDateRange) {
    if (!this.selectedDateRange) {
      return true;
    }
    const start = newDateRange.start === this.selectedDateRange.start;
    const newEnd = moment(newDateRange.end, DATE_FORMAT_STRING);
    const currEnd = moment(this.selectedDateRange.end, DATE_FORMAT_STRING);
    const differnce = newEnd.diff(currEnd, 'minutes');
    return !(start && differnce <= 15);
  }

  setGranularity(granularity: Granularity): void {
    this.selectedGranularity$.next(granularity);
    this.previousGranularityID = granularity.id;
    this.deeplinkService.updateGranularity(granularity.code);
  }

  getDefaultGranularity(granularities: Granularity[]): Granularity {
    if (this.isInit && this.deeplinkedGranularity) {
      let deeplinkedGranularity = granularities.find((gran) => gran.code === this.deeplinkedGranularity);
      if (deeplinkedGranularity) {
        return deeplinkedGranularity;
      }
    }
    this.isInit = false;

    let previousGranularity = granularities.find((gran) => gran.id === this.previousGranularityID);

    let granularityToValidate;

    if (previousGranularity !== undefined) {
      granularityToValidate = previousGranularity;
    } else {
      granularityToValidate = granularities[granularities.length - 1];
    }
    return granularityToValidate;
  }

  validateAndSelectGranularity(granularity: Granularity) {
    if (granularity) {
      if (parseInt(granularity.duration_in_milliseconds) < this.minReportingInterval$.value) {
        const closestGranularity = this.availableGranularities$.value.find((gran) => {
          return parseInt(gran.duration_in_milliseconds) >= this.minReportingInterval$.value;
        });
        if (closestGranularity) {
          this.setGranularity(closestGranularity);
        }
      } else {
        this.setGranularity(granularity);
      }
    }
  }

  calculateMinReportingInterval(trends: Trend[]) {
    let minReportingInterval = trends.reduce((min, trend) => {
      return trend.channel.reporting_interval_ms > min ? trend.channel.reporting_interval_ms : min;
    }, trends[0].channel.reporting_interval_ms);
    if (minReportingInterval <= this.maxReportingInterval) {
      this.minReportingInterval$.next(minReportingInterval);
    } else {
      this.minReportingInterval$.next(this.maxReportingInterval);
    }
  }
}
