import { UserService } from '../services/user.service';
import { LoadingService } from './../services/loading.service';
import { ExportService } from './../services/export.service';
import { ReadingsService } from './../services/readings.service';
import { Component, OnDestroy, AfterViewInit, ViewChild, Injectable } from '@angular/core';
import { Trend } from '../models/trend.model';
import { TrendsService } from '../services/trends.service';
import { ReadingsErrorsService } from './../services/readings-error.service';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RawDataTableMessagesComponent } from './raw-data-table-messages/raw-data-table-messages.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MetadataDialog } from './metadata-dialog/metadata-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export const NO_VERBOSE_CHANNELS = ['268'];

@Injectable()
export class RawDataTablePaginator implements MatPaginatorIntl {
  changes = new Subject<void>();
  firstPageLabel = '';
  lastPageLabel = '';
  nextPageLabel = '';
  previousPageLabel = '';
  itemsPerPageLabel = '';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    const first = page * pageSize;
    const last = first + pageSize > length ? length : first + pageSize;
    return `${first + 1} - ${last} : ${length}`;
  }
}
@Component({
  selector: 'app-raw-data-table',
  templateUrl: './raw-data-table.component.html',
  styleUrls: ['./raw-data-table.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: RawDataTablePaginator }],
})
export class RawDataTableComponent implements OnDestroy, AfterViewInit {
  displayedColumns = ['start-time', 'end-time'];
  trends: any[];
  noneAggregatedTrends: any[];
  columnTrends: Trend[];
  loading = true;
  unsubscriber$ = new Subject();
  converting = false;
  verboseMode = false;
  filterMetadata = false;
  selectedVerboseChannels = false;

  tableData = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private exportService: ExportService,
    private trendsService: TrendsService,
    private loadingService: LoadingService,
    private errorMessage: MatSnackBar,
    private readingsErrorsService: ReadingsErrorsService,
    private readingsService: ReadingsService,
    private dialog: MatDialog,
    private userService: UserService,
  ) {
    this.readingsErrorsService.error$.pipe(takeUntil(this.unsubscriber$)).subscribe(error => {
      console.log({ error });
      if (this.isSelectedTrend(error.id)) {
        this.showErrorMessage(error.node, error.channel);
      }
    });

    this.trendsService.trends$
      .pipe(
        takeUntil(this.unsubscriber$),
        map(trends => trends.filter(trend => !trend.id.includes('_past'))),
        tap(trends => {
          this.selectedVerboseChannels = false;
          trends.forEach(trend => {
            if (!NO_VERBOSE_CHANNELS.includes(trend.channel.id)) {
              this.selectedVerboseChannels = true;
            }
          });
          if (!this.selectedVerboseChannels && this.verboseMode) {
            this.verboseMode = false;
            this.toggleMetadata();
          }
        }),
      )
      .subscribe(trends => {
        this.tableData.data = [];
        this.filterMetadata = false;
        this.tableData.filter = '';
        const removedTrend = trends.reduce((previuslySelected, trend) => {
          return !previuslySelected ? false : this.displayedColumns.includes(trend.id);
        }, true);
        this.columnTrends = [...trends];
        this.displayedColumns = [];
        this.trends = trends.filter(trend => trend.isAggregated);
        this.noneAggregatedTrends = trends
          .filter(trend => !trend.isAggregated)
          .map(trend => [{ id: `${trend.id}_time`, label: 'Timestamp', timestamp: true }, trend])
          .flat();

        if (this.trends.length > 0) {
          this.displayedColumns = ['start-time', 'end-time'];
        }
        this.displayedColumns = [
          ...this.displayedColumns,
          ...this.trends.map(trend => trend.id),
          ...this.noneAggregatedTrends.map(trend => trend.id),
        ];
        for (let i = 0; i < 10; i++) {
          this.tableData.data = [...this.tableData.data, ...this.displayedColumns.map(e => '')];
        }
        if (removedTrend && trends.length > 0) {
          this.updateRawDataTable();
        }
      });

    this.loadingService.loading$.pipe(takeUntil(this.unsubscriber$)).subscribe(loading => {
      this.loading = loading;
      if (!loading) {
        this.updateRawDataTable();
      }
    });
  }

  updateRawDataTable() {
    this.converting = true;
    setTimeout(() => {
      this.tableData.data = this.exportService.summariesTo2DArray(this.columnTrends, true);
      this.trends = this.addMetadataColumn(this.trends);
      this.noneAggregatedTrends = this.addMetadataColumn(this.noneAggregatedTrends);
      this.updateDisplayedColumns();
      this.tableData.data = this.tableData.data.splice(3);
      this.converting = false;
    }, 0);
  }

  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
    this.tableData.filterPredicate = this.metadataFilter();
  }

  loadingTrends() {
    return this.trends.some(t => t.loading) || this.converting;
  }

  isSelectedTrend(trendId) {
    return this.trends.findIndex(trend => trend.id === trendId) >= 0;
  }

  showErrorMessage(space, channel) {
    this.errorMessage.openFromComponent(RawDataTableMessagesComponent, {
      duration: 4000,
      horizontalPosition: 'right',
      data: { space, channel },
    });
  }

  hasVerboseResource() {
    return this.userService.hasPermission('VERBOSE_MODE', 'READ');
  }

  getTableIndex(index) {
    if (this.trends.length > 0) {
      return index + 2 + this.trends.length;
    }
    return index;
  }

  addMetadataColumn(columns) {
    return columns
      .filter(column => !column.metadata)
      .map(column => {
        if (column.verbose) {
          return [column, { metadata: true, label: 'Metadata', id: `${column.id}meta` }];
        }
        return [column];
      })
      .flat();
  }

  toggleMetadata() {
    this.readingsService.setVerboseMode(this.verboseMode);
    this.trendsService.refreshReadings();
    this.toggleMetadataColumn();
    if (!this.verboseMode) {
      this.filterMetadata = false;
      this.toggleFilterMetadata();
    }
  }

  toggleFilterMetadata() {
    this.tableData.filter = this.filterMetadata ? 'true' : '';
  }

  removeMetadataColumn() {
    this.trends = this.trends.filter(column => !column.metadata);
  }

  updateDisplayedColumns() {
    this.displayedColumns = [];
    if (this.trends.length > 0) {
      this.displayedColumns = ['start-time', 'end-time'];
    }
    this.displayedColumns = [
      ...this.displayedColumns,
      ...this.trends.map(trend => trend.id),
      ...this.noneAggregatedTrends.map(trend => trend.id),
    ];
  }

  toggleMetadataColumn() {
    if (!this.verboseMode) {
      this.removeMetadataColumn();
    }
    this.updateDisplayedColumns();
  }

  showAllMetadata(metadata) {
    this.dialog.open(MetadataDialog, {
      data: {
        ...metadata,
      },
    });
  }

  findMetadataAnomalities(index) {
    let readingCount = 0;
    let anomalitiesCount = 0;
    this.tableData.data.forEach(metadata => {
      readingCount++;
      if (this.checkMetadataAnomaly(metadata[index + 2])) {
        anomalitiesCount++;
      }
    });
    return `${anomalitiesCount}/${readingCount}`;
  }

  metadataFilter() {
    const filterFunction = (data: any, filter: string): boolean => {
      const activate = Boolean(filter);
      let anomalySearch = () => {
        let foundAnomality = false;
        if (activate) {
          data.forEach((value, i) => {
            if (i < 3) {
              return;
            }
            if (typeof value === 'object' && !foundAnomality) {
              foundAnomality = this.checkMetadataAnomaly(value);
            }
          });
          return foundAnomality;
        } else {
          return true;
        }
      };
      return anomalySearch();
    };
    return filterFunction;
  }

  hasReadings() {
    return (
      this.trends.reduce((acc, trend) => {
        return acc ? true : trend.hasValues;
      }, false) ||
      this.noneAggregatedTrends.reduce((acc, trend) => {
        return acc ? true : trend.hasValues;
      }, false)
    );
  }

  hasSelectedTrends() {
    return this.trends.length > 0 || this.noneAggregatedTrends.length > 0;
  }

  checkMetadataAnomaly(metadata) {
    if (!metadata || Object.keys(metadata).length == 0) {
      return false;
    }
    if (
      metadata.anomaly_type === 0 &&
      metadata.user_estimated === false &&
      metadata.utility_data === false &&
      metadata.vee_estimated === false &&
      metadata.actual === metadata.expected
    ) {
      return false;
    }
    return true;
  }

  isNotempty(metadata) {
    return metadata && Object.keys(metadata).length !== 0 ? true : false;
  }

  ngOnDestroy() {
    if (this.verboseMode) {
      this.readingsService.setVerboseMode(false);
      this.trendsService.refreshReadings();
    }
    this.unsubscriber$.next(null);
    this.unsubscriber$.complete();
  }
}
