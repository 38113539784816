import { filter, map } from 'rxjs/operators';
import { Granularity } from './../../models/granularity.model';
import { GranularityService } from 'src/app/services/granularity.service';
import { Channel } from './../../models/channel.model';
import { ChannelCategory } from './../../models/channel-category.model';
import { ComparisonType } from './../../models/comparisonType.model';
import { Trend } from './../../models/trend.model';
import { ComparisonService } from '../../services/comparison.service';
import { TrendsService } from '../../services/trends.service';
import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Node } from '../../models/node.model';
import { Observable } from 'rxjs';
import { MixPanelService } from '../../services/mixpanel.service';

const MAX_RAW_DATA_TRENDS = 5;
const MAX_GRAPH_UNIQUE_UOMS = 3;
const MAX_CHANNELS_PER_SPACE = 3;

const METERED_CHANNELS = ['MeasuredPoint'];
const BASELINE_CHANNELS = ['BaselinePoint', 'DRBaselinePoint'];
const TELEMETRY_CHANNELS = ['TelemetryPoint'];
@Component({
  selector: 'app-node-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class NodeCardComponent implements OnDestroy, OnInit {
  subscriptions = [];
  deleted = false;

  nodeChannels: Channel[] = [];
  maxSelections: number;
  selectedUOMs: Set<string>;
  selectedChannels: Channel[] = [];
  categories: ChannelCategory[] = [];
  selectedComparisonType: ComparisonType;
  loading = true;
  selectedTrends: Trend[];
  availableSelections: number = 1;
  selectedGranularity: Granularity;

  selectedMeteredChannels: Channel[] = [];
  selectedBaselineChannels: Channel[] = [];
  selectedTelemetryChannels: Channel[] = [];

  meteredTrends: Observable<Trend[]>;
  baselineTrends: Observable<Trend[]>;
  telemetryTrends: Observable<Trend[]>;

  baselineChannels: any = [];

  nodeTrends: Observable<Trend[]>;

  @Input() node: Node;
  @Input() index: number;

  @Output() unselectNode: EventEmitter<Node> = new EventEmitter();

  constructor(
    private trendsService: TrendsService,
    private comparisonService: ComparisonService,
    private granularityService: GranularityService,
    private mixpanelService: MixPanelService
  ) {
    this.nodeTrends = this.trendsService.trends$.pipe(
      filter((trends: Trend[]) => {
        return trends.some(trend => trend.node.id == this.node.id);
      }),
      map(trends => trends.filter(trend => trend.node.id == this.node.id)),
    );

    this.meteredTrends = this.nodeTrends.pipe(
      map((trends: Trend[]) => {
        return trends.filter(trend => this.selectedMeteredChannels.includes(trend.channel));
      }),
    );

    this.baselineTrends = this.nodeTrends.pipe(
      map((trends: Trend[]) => {
        return trends.filter(trend => this.selectedBaselineChannels.includes(trend.channel));
      }),
    );

    this.telemetryTrends = this.nodeTrends.pipe(
      map((trends: Trend[]) => {
        return trends.filter(trend => this.selectedTelemetryChannels.includes(trend.channel));
      }),
    );
  }

  handleSelectionChange(event, channel, channelPointType) {
    this.mixpanelService.channelSelected(channel.id, channel.displayLabel, channelPointType, this.selectedComparisonType.type)
    const changeChannels = event.value;
    let selectedChannels = [];
    switch (channelPointType) {
      case 'Telemetry':
        selectedChannels = this.getSelectedTelemetryChannels();
        break;
      case 'Baseline':
        selectedChannels = this.getSelectedBaselineChannels();
        break;
      case 'Metered':
        selectedChannels = this.getSelectedMeteredChannels();
        break;
    }

    this.refreshSelections();

    if (selectedChannels.some(selectedChannel => JSON.stringify(selectedChannel) == JSON.stringify(channel))) {
      this.node.deselectChannels([channel]);
    } else {
      this.availableSelections--;
      this.node.selectChannels([channel]);
    }
  }

  unselect(e: Event) {
    this.deleted = true;
    e.stopImmediatePropagation();
    this.node.deselectChannels(this.selectedChannels);
    this.unselectNode.emit(this.node);
  }

  deselectAllChannelsExceptFirst() {
    this.selectedChannels.forEach((channel, index) => {
      if (index >= 1) {
        this.node.deselectChannels([channel]);
      }
    });
  }

  ngOnInit() {
    const granularitySubscription = this.granularityService.selectedGranularity$.subscribe(granularity => {
      this.selectedGranularity = granularity;
    });
    const trendsSubscription = this.trendsService.trends$.subscribe(trends => {
      this.selectedTrends = trends;
      this.availableSelections = MAX_RAW_DATA_TRENDS >= trends.length ? MAX_RAW_DATA_TRENDS - trends.length : 0;
    });
    const channelSubscription = this.node.channelCategories$.subscribe(newChannelCategories => {
      this.getChannelsList(newChannelCategories);
    });

    const baselineChannelsSubscription = this.node.baselineChannels$.subscribe(baselineChannels => {
      if (baselineChannels.length > 0) {
        this.baselineChannels = baselineChannels;
      }
    });
    const selectedChannelSubscription = this.node.selectedChannels$.subscribe(selectedChannels => {
      this.selectedChannels = selectedChannels;
      this.refreshSelections();
    });
    const selectedUOMSubscription = this.trendsService.selectedUOMs$.subscribe(selectedUOMs => {
      this.selectedUOMs = selectedUOMs;
    });
    const loadingSubscription = this.node.loadingChannels$.subscribe(loading => {
      this.loading = loading;
    });
    const selectedComparisonTypeSubscription = this.comparisonService.selectedComparisonType$.subscribe(
      selectedComparisonType => {
        this.selectedComparisonType = selectedComparisonType;
        if (selectedComparisonType.type === 'past') {
          this.deselectAllChannelsExceptFirst();
          this.maxSelections = 1;
        }
        if (selectedComparisonType.type === 'raw') {
          this.trendsService.removeTrendsUpTo(MAX_RAW_DATA_TRENDS);
          this.maxSelections = MAX_RAW_DATA_TRENDS;
          this.refreshSelections();
        }
        if (selectedComparisonType.type === 'trends') {
          this.deselectOverMaxUniqueUOMs(MAX_GRAPH_UNIQUE_UOMS);
          this.maxSelections = MAX_GRAPH_UNIQUE_UOMS;
        }
      },
    );

    this.subscriptions = [
      loadingSubscription,
      channelSubscription,
      granularitySubscription,
      selectedChannelSubscription,
      selectedUOMSubscription,
      selectedComparisonTypeSubscription,
      trendsSubscription,
      baselineChannelsSubscription,
    ];
  }

  refreshSelections() {
    this.selectedMeteredChannels = this.getSelectedMeteredChannels();
    this.selectedBaselineChannels = this.getSelectedBaselineChannels();
    this.selectedTelemetryChannels = this.getSelectedTelemetryChannels();
  }

  getChannelsList(categories: ChannelCategory[]) {
    categories.forEach(category => {
      this.nodeChannels.push(...category.channels);
    });
  }

  deselectOverMaxUniqueUOMs(MAX_UNIQUE_UOMS: number) {
    const uniqueUOMs = new Set();
    this.selectedTrends
      .map(trend => trend.channel.uomId)
      .forEach(uom => {
        if (uniqueUOMs.size < MAX_UNIQUE_UOMS) {
          uniqueUOMs.add(uom);
        }
      });
    this.selectedChannels.forEach(channel => {
      if (!uniqueUOMs.has(channel.uomId)) {
        this.node.deselectChannels([channel]);
      }
    });
  }

  getChannelGranularityLabel(code) {
    const granularity = this.granularityService.allgranularities.find(granularity => granularity.code === code);
    return granularity.display_label;
  }

  getSelectedGranularityLabel() {
    return this.selectedGranularity.display_label;
  }

  getTelemetryChannels() {
    return this.nodeChannels.filter(channel => TELEMETRY_CHANNELS.includes(channel.pointType));
  }

  getBaselineChannels() {
    const fromNode = this.nodeChannels.filter(channel => BASELINE_CHANNELS.includes(channel.pointType));
    return [...fromNode, ...this.baselineChannels];
  }

  getMeteredChannels() {
    return this.nodeChannels.filter(channel => METERED_CHANNELS.includes(channel.pointType));
  }

  getSelectedTelemetryChannels() {
    return this.selectedChannels.filter(channel => TELEMETRY_CHANNELS.includes(channel.pointType));
  }

  getSelectedBaselineChannels() {
    return this.selectedChannels.filter(channel => BASELINE_CHANNELS.includes(channel.pointType));
  }

  getSelectedMeteredChannels() {
    return this.selectedChannels.filter(channel => METERED_CHANNELS.includes(channel.pointType));
  }

  disableChannel(channel) {
    return (
      (this.selectedUOMs.size >= this.maxSelections && !this.selectedUOMs.has(channel.uomId)) ||
      (this.availableSelections == 0 && !channel.selected && this.selectedComparisonType.type == 'raw') ||
      (this.selectedChannels.length >= MAX_CHANNELS_PER_SPACE && !channel.selected)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
