import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import _ from 'lodash';

import { TranslateService } from '@ngx-translate/core';
import { CookieService } from './cookie.service';
import * as moment from 'moment-timezone';

// this language will be used as a fallback when a translation isn't found in the current language
export const DEFAULT_LOCALE = 'en-us';
const DEFAULT_BRAND = 'ENELX';
const SUPPORTED_LOCALES = ['en-au', 'en-nz', 'en-us', 'nb-no', 'it-it', 'pl-pl', 'ja-jp', 'zh-tw', 'en-ie', 'en-gb'];
const SUPPORTED_BRANDS = ['enelx', 'demo', 'consumersenergy', 'dte'];

const BILLION = 1000000000;
const MILLION = 1000000;
const THOUSAND = 1000;

/**
 * Wrapping the translateService for enernoc purposes, including fallback rules, branding, etc
 */
@Injectable()
export class I18nService {
  locale: string;
  brand: string;
  currencyFormatter: any;
  shortDateFormatter: any;
  shortDateMonthFormatter: any;
  longDateFormatter: any;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: any,
  ) {
    this.translate.setDefaultLang(`${DEFAULT_LOCALE}.${DEFAULT_BRAND}`);
    this.setLocale(this.cookieService.getLocale() || DEFAULT_LOCALE);
    this.setBrand((this.cookieService.getBrand() || DEFAULT_BRAND).toUpperCase());
    this.loadLocale();
  }

  private loadLocale() {
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const brandLocale = `${this.locale || DEFAULT_LOCALE}.${this.brand || DEFAULT_BRAND}`;
    console.log(`using brand/locale  ${brandLocale}`);
    this.translate.use(brandLocale);
    moment.locale(this.locale);
    this.setDateFormatters(this.locale);
    this.setCurrencyFormatter('USD');
  }

  getString(key) {
    const result = this.interpolate(key, null);
    return _.isEmpty(result) ? '' : result;
  }

  getStringAsync(key): Promise<string> {
    return new Promise((resolve) => {
      return this.translate.get(key).subscribe((res: string) => {
        resolve(res);
      });
    });
  }

  interpolate(key, interpolateObj) {
    const result = this.translate.instant(key, interpolateObj);
    if (result === key) {
      console.error('failed to translate', key);
    }
    return result;
  }

  setLocale(locale) {
    if (typeof locale === 'string') {
      locale = locale.toLowerCase();
      if (locale.indexOf('_') !== -1) {
        locale = locale.replace('_', '-');
      }
      if (!SUPPORTED_LOCALES.includes(locale)) {
        locale = DEFAULT_LOCALE;
      }
    }
    this.locale = locale || DEFAULT_LOCALE;
    if (!this.isNotEmpty(this.locale)) {
      this.locale = DEFAULT_LOCALE;
    }
  }

  setBrand(brand) {
    if (!SUPPORTED_BRANDS.includes(brand.toLowerCase())) {
      brand = DEFAULT_BRAND;
    }
    this.brand = brand || DEFAULT_BRAND;
    if (!this.isNotEmpty(this.brand)) {
      this.brand = DEFAULT_BRAND;
    }
  }

  isNotEmpty(string) {
    return typeof string === 'string' && string.length > 0 && string !== 'undefined';
  }

  setCurrencyFormatter(currency) {
    this.currencyFormatter = this.getCurrencyFormatterForCurrency(currency);
  }

  setDateFormatters(locale) {
    this.shortDateMonthFormatter = new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: '2-digit',
    });

    this.shortDateFormatter = new Intl.DateTimeFormat(locale, {
      // year : 'numeric',
      year: 'numeric',
      month: 'short',
    });

    this.longDateFormatter = new Intl.DateTimeFormat(locale, {
      weekday: 'long',
      day: '2-digit',
      year: 'numeric',
      month: 'short',
    });
  }

  getCurrencyFormatterForCurrency(currency) {
    const numberFormatterOpts = {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    };

    return new Intl.NumberFormat(this.locale, numberFormatterOpts);
  }

  formatShortDate(date) {
    try {
      return this.shortDateFormatter.format(new Date(date));
    } catch (e) {
      return date;
    }
  }

  formatShortDateMonth(date) {
    try {
      return this.shortDateMonthFormatter.format(new Date(date));
    } catch (e) {
      return date;
    }
  }

  formatLongDate(date) {
    try {
      return this.longDateFormatter.format(new Date(date));
    } catch (e) {
      return date;
    }
  }

  getUnderscoreLocale() {
    const splitted = this.locale.split('-');
    return `${splitted[0]}_${splitted[1].toUpperCase()}`;
  }

  private calculateUnit(value) {
    let unit = this.getString('markets.projected_annual_savings.THOUSAND');
    if (value >= BILLION) {
      unit = this.getString('markets.projected_annual_savings.BILLION');
    } else if (value >= MILLION) {
      unit = this.getString('markets.projected_annual_savings.MILLION');
    }
    return unit;
  }

  private calculateReducedValue(value, digits: number) {
    if (value >= BILLION) {
      value = (value / BILLION).toFixed(digits);
    } else if (value >= MILLION) {
      value = (value / MILLION).toFixed(digits);
    } else {
      value = (value / THOUSAND).toFixed(digits);
    }
    return value;
  }
}
