import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuData, Context, Permission, ContextSelectorService, Space } from 'ngx-global-nav';
import { ProfileMeta } from 'ngx-global-nav/dist/ngx-global-nav/lib/models/profile-meta.models';
import { NavService } from 'src/app/services/global-nav.service';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { environment } from 'src/environments/environment.prod';
import { ApiEndpointsService } from './services/api-endpoints.service';
import { DeeplinksService } from './services/deeplinks.service';
import { UserService } from './services/user.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Router, Event, NavigationEnd } from '@angular/router';
import { TimezonesService } from './services/timezones.service';
import { I18nService } from './services/i18n.service';
import { NodeService } from './services/node.service';
import { ComparisonType } from './models/comparisonType.model';
import { ComparisonService } from './services/comparison.service';
import { MixPanelService } from './services/mixpanel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  navMenu: MenuData;
  navMetadata: ProfileMeta;
  contextList: Context[] = [];
  isLoading: boolean = false;
  theme: string;
  disabledContextValues = ['Admingroup'];
  defaultBrand = 'enelx';
  permissions: Permission[];
  navUrl = '';
  selectedComparisonType: ComparisonType;
  sidenavOpen = true;
  isInit = true;
  deeplinkedOrgs: string;

  constructor(
    private userService: UserService,
    private translate: TranslateService,
    private apiEndpointsService: ApiEndpointsService,
    private contextSelectorService: ContextSelectorService,
    private navService: NavService,
    private cookieService: NgxCookieService,
    private nodeService: NodeService,
    private timezonesService: TimezonesService,
    private overlayContainer: OverlayContainer,
    private deeplinkService: DeeplinksService,
    private router: Router,
    private i18nService: I18nService,
    private comparisonService: ComparisonService,
    private mixpanelService: MixPanelService,
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const {
          queryParamMap: { params },
        } = router.parseUrl(event.url) as any;

        // Hide loading indicator
        const urlParts = event.url.split('?');
        const queryParams = urlParts[1];
        const pathBrand = urlParts[0].split('/')[1];

        const brand = (this.cookieService.getCookie('default_brand') || '').toLowerCase();

        if (brand && pathBrand !== brand && brand !== this.defaultBrand) {
          router.navigate([`${brand}`], { queryParams: params });
        } else if (brand && pathBrand !== '' && brand === this.defaultBrand) {
          router.navigate(['/'], { queryParams: params });
        }
        this.theme = brand || this.defaultBrand;
        overlayContainer.getContainerElement().classList.add(this.theme);
      }
    });
    this.comparisonService.selectedComparisonType$.subscribe((comparisonType) => {
      this.selectedComparisonType = comparisonType;
    });

    this.nodeService.contextList$.subscribe((spaces: Space[]) => {
      if (spaces) {
        this.contextList = spaces.map((space) => {
          return { id: space.id, label: space.display_label, space_type: space.space_type, ...space };
        });
        this.isInit = false;
        let defaultOrgs;
        if (this.deeplinkedOrgs && this.deeplinkedOrgs.length) {
          defaultOrgs = this.deeplinkedOrgs[0];
        }
        this.contextSelectorService.setContextList(spaces, defaultOrgs);
      }
    });

    this.deeplinkService.initialDeeplinks$.subscribe((deeplinks) => {
      if (deeplinks.orgs) {
        this.deeplinkedOrgs = deeplinks.orgs[0];
      }
    });

    this.contextSelectorService.currentContext$.subscribe((orgs: Context[]) => {
      if (!this.isInit && orgs?.length) {
        this.deeplinkService.updateOrgs(orgs);
      }
    });

    this.getNavUrl();
  }

  loadData() {
    this.nodeService.getContextList();
    this.timezonesService.getTimezones();
  }

  async getNavUrl() {
    this.navUrl = await this.navService.getNavUrl();
  }

  ngOnInit(): void {
    this.mixpanelService.viewPage();
    this.userService.appPermissions$.subscribe((permissions) => {
      this.permissions = permissions;
      this.loadData();
    });

    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand}`).subscribe(() => {
      this.translate.get('trends.title').subscribe((result) => {
        document.title = result;
      });
    });
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width > 1280;
  }

  sendDrawerActionToMixpanel() {
    if (this.sidenavOpen) {
      this.mixpanelService.collapseDrawer();
    } else {
      this.mixpanelService.expandDrawer();
    }
  }
}
