import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-raw-data-table-messages',
  templateUrl: './raw-data-table-messages.component.html',
  styleUrls: ['./raw-data-table-messages.component.scss'],
})
export class RawDataTableMessagesComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
