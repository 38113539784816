import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Timezone } from '../models/timezone.model';
import { TrendsApiService } from './trends-api.service';

@Injectable()
export class TimezonesService {
  timezones: Timezone[] = [];

  constructor(private nodeApiService: TrendsApiService) {}

  async getTimezones() {
    const timezones = await this.nodeApiService.getTimezones();
    if (timezones && Array.isArray(timezones)) {
      this.timezones.push(...convertKeys.toCamel<Timezone[]>(timezones));
    }
  }

  findTimezone(timezoneName): Timezone {
    if (this.timezones && this.timezones.length) {
      let foundTimezone = this.timezones.find(tz => tz.timezoneName === timezoneName);
      if (foundTimezone) {
        return foundTimezone;
      }
    }
  }
}
