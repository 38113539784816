import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NodeType } from '../models/nodeType.model';

@Injectable()
export class NodeTypeService {
  private defaultNodeType = 'organization';
  selectedNodeType$ = new BehaviorSubject<NodeType>(null);
  availableNodeTypes$ = new BehaviorSubject<NodeType[]>([]);
  loadingNodes$ = new BehaviorSubject<boolean>(true);

  setNodeType(type: string, isUtilityPortalResource = false) {
    let selected = this.availableNodeTypes$.value.find(nodeType => nodeType.type === type);

    if (!selected && isUtilityPortalResource) {
      selected = this.availableNodeTypes$.value.find(nodeType => nodeType.type === 'program');
      console.log('Node Type doesnt exist, using default', type);
    }

    if (!selected) {
      selected = this.availableNodeTypes$.value.find(nodeType => nodeType.type === this.defaultNodeType);
      console.log('Node Type doesnt exist, using default', type);
    }
    this.loadingNodes$.next(true);
    this.selectedNodeType$.next(selected);
  }
}
